import store from '@/store/index.js';
import { customRef } from 'vue'

function generateURL(denominacion, codigo, idItem) {
    // eslint-disable-next-line
    const regex = /[!@#$%^&*()\/_=\+\,\.?":{}|<>\-]/g

    if (idItem === undefined) idItem = codigo

    let url = denominacion.includes(codigo) ? denominacion.replace(codigo, "") : denominacion.replace(codigo, "")
    url = url.replaceAll(regex, "");
    url = url.replaceAll("  ", " ");
    url = url.trim()
    url = url.replaceAll(" ", "-")
    url += `/${encodeURIComponent(idItem)}/`


    return url.toLowerCase();
}

function synchronizeConfig(config) {

    const actualConfig = store.getters["website/siteConfiguration"]

    if (Object.entries(actualConfig).length === 0) {
        console.log("CAMBIANDO CONFIGURACION DEL SITIO")
        store.dispatch("website/setConfiguration", config)
    } else {

        //eslint-disable-next-line
        for (let [key, value] of Object.entries(actualConfig)) {
            if (actualConfig[key] !== config[key]) {
                store.dispatch("website/setConfiguration", config)
                break;
            }
        }

    }



}

function dummyData(type, dummyItemCount) {
    const dummyData = []; // Array para almacenar datos ficticios
    const data = {
        "products": {
            id: 0,
            denominacion: `null`,
            PRECIO: `ARS ${(Math.random() * 10000).toFixed(2)}`, // Precio aleatorio
            marca: "Marca de ejemplo",
            stock: Math.floor(Math.random() * 100), // Stock aleatorio
            codigo: `0`,
            tSInsert: Math.floor(Date.now() / 1000), // Fecha actual en formato UNIX
            surchargeApplied: { value: Math.floor(Math.random() * 50), type: '' }, // Recargo aleatorio
            itemName: `${0}.jpg`,
            idImage: 0,
            image: `${0}.jpg`,
            isError: false,
            message: ""
        },
        "brands": {
            marca: "marca",
            value: "value",
            id: 1,
            file: "logo.png",
            priority: 1
        }
    }

    // Generar datos ficticios
    for (let i = 0; i < dummyItemCount; i++) {
        dummyData.push(data[type]);
    }

    return dummyData;
}
//eslint-disable-next-line
function useDebouncedRef(value, delay = 200, useTimeOut = true) {
    //eslint-disable-next-line
    let timeout
    return customRef((track, trigger) => {
        return {
            get() {
                track()
                return value
            },
            set(newValue) {

                if (useTimeOut) {
                    clearTimeout(timeout)
                    timeout = setTimeout(() => {
                        value = newValue
                    }, delay)
                    trigger()
                } else {

                    value = newValue
                    trigger()
                }

            }
        }
    })
}


export { generateURL, synchronizeConfig, dummyData, useDebouncedRef }
