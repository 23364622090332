
// initial state
// shape: [{ id, quantity }]
const state = {
  cart: JSON.parse(localStorage.getItem("cart")) || []
}

// getters
const getters = {
  cart: (state) => {
    return state.cart;
  },
  itemsOnCart: (state) => {
    return state.cart.length
  }
}

// actions
const actions = {

  addProductToCart ({ state, commit }, product) {

      const cartItem = state.cart.find(item => item.id === product.id)
      if (!cartItem) {
        commit('pushProductToCart', { id: product.id })
      } else {
        commit('incrementItemQuantity', cartItem)
      }
      // remove 1 item from stock
      commit('products/decrementProductInventory', { id: product.id }, { root: true })

  }
}

// mutations
const mutations = {
  pushProductToCart (state, { id }) {
    state.cart.push({
      id,
      quantity: 1
    })
  },

  incrementItemQuantity (state, { id }) {
    const cartItem = state.items.find(item => item.id === id)
    cartItem.quantity++
  },

  setCart (state, { items }) {
    state.cart = items
  },

}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
