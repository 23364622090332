<template>

    <ArticleComponent v-for="item in productsRef" :key="item.id" :article="item" :isError="(item.isError) ?? false"
        :message='(item.message) ?? ""' :surcharge="item.inputSurcharge" :skeleton="skeleton"
        @handle-input="(n) => collectMarkups(n, item.id)" />

    <SaveConfigurationComponent :webConfiguration="surcharges" :skeleton="skeleton"
        @remove-surcharge-change="removeSurchargeChange" @reload-products="emptySurcharges" />
    <Warning :activatorProps="warning" @close-warning="(val) => warning = val" @continue-navigation="navigateTo" />

</template>

<script setup>

import {
    defineProps, ref, getCurrentInstance, defineExpose,
    watch,
    onBeforeMount,
    onMounted,
    defineEmits
} from 'vue'
import { useStore } from 'vuex'
import { useRouter, onBeforeRouteLeave } from 'vue-router'
import ArticleComponent from "@/components/ArticleComponent.vue";
import SaveConfigurationComponent from "@/components/SaveConfigButton.vue";
import Warning from "@/components/BeforeLeaveWarning.vue";
import { dummyData } from '@/utils/utils.js';

function emptySurcharges() {
    surcharges.value = {}
    emit('reloadProducts')
}
function removeSurchargeChange(data) {

    let inputCategory = {};
    let actualSurcharge = store.getters["website/siteConfiguration"].surcharges || configuration.surcharges
    let previousValue = actualSurcharge.find((element) => element.ID === data.id && element.TIPO === "CAT")
    let generalMarkup;

    delete surcharges.value[data.type][data.id]
    if (Object.entries(surcharges.value[data.type]).length === 0) {
        delete surcharges.value[data.type]
    }

    const restorePreviousProductSurcharge = (product) => {

        if (surcharges.value["ART"] && surcharges.value["ART"][product.id]) {

            if (surcharges.value["ART"][product.id].RECARGO === 0) {
                applyDefaultCategorySurcharge(product)
            } else {
                applySurcharge(product, surcharges.value["ART"][product.id].RECARGO, "ART")
            }

        } else {
            let productSurcharge = actualSurcharge.find((element) => element.ID === product.id && element.TIPO === "ART")

            if (productSurcharge === undefined) {
                applyDefaultCategorySurcharge(product)
            } else {
                applySurcharge(product, productSurcharge.RECARGO, "ART")
            }
        }
    }

    if (data.type === "ART") {
        //The product markup has been removed, so I look for the first available position in the markup hierarchy

        let product = productsRef.value.find((element) => element.id === data.id)
        restorePreviousProductSurcharge(product)


    } else if (data.type === "TODOS") {

        generalMarkup = actualSurcharge.find((element) => element.TIPO === "TODOS")

        for (let item of productsRef.value) {
            if (item.surchargeApplied.type === "TODOS") {
                applyDefaultCategorySurcharge(item)
            }
        }

        emitter.emit("handleTotalSurcharge", {
            ID: "-1",
            RECARGO: generalMarkup === undefined ? 0 : generalMarkup.RECARGO,
            validations: {
                isError: false,
                message: "",
            }
        })
    } else {
        //The user deleted the category surcharge, so re evaluate all products from the category

        for (let item of productsRef.value) {
            restorePreviousProductSurcharge(item)
        }

        inputCategory.message = ""
        inputCategory.isError = false
        inputCategory.surcharge = previousValue === undefined ? 0 : previousValue.RECARGO
        exposedInputCategory.value = Object.assign(exposedInputCategory.value, inputCategory)
    }



}
function getProduct(id) {

    let product = null;

    for (const [key, value] of Object.entries(props.products)) {

        if (value.id === Number(id)) {
            product = productsRef.value[key]
            break;
        }
    }

    return product
}
function setCategoryInput(categoryName) {
    const findCategoryByUrl = (categoryName) => {

        let categories = store.getters["categories/categories"]
        let category = categories.find((element) => element.name.toLowerCase() === categoryName);

        if (category === undefined && categoryName.includes("--")) {
            categoryName = categoryName.split("--")[1];

            for (let i = 0; i < categories.length; i++) {
                const father = categories[i];
                if (father.childrens.length > 0) {
                    category = father.childrens.find((element) => element.name.toLowerCase() === categoryName);
                    if (category !== undefined) {
                        break;
                    }
                }
            }

        }

        return category
    }
    const localConfiguration = store.getters["website/siteConfiguration"].surcharges;

    const category = findCategoryByUrl(categoryName);
    const inputCategory = { ...category };

    const categorySurcharges = surcharges.value?.CAT?.[category.id];
    const surchargeInfo = categorySurcharges || localConfiguration.find(config => config.ID === category.id) || { RECARGO: 0, isError: false, message: "" };

    inputCategory.surcharge = surchargeInfo.RECARGO;
    inputCategory.isError = surchargeInfo.isError;
    inputCategory.message = surchargeInfo.message;

    return inputCategory;
}
function applySurcharge(product, surcharge, type) {
    let objToAssign = {
        surchargeApplied: {}
    }

    let aux = priceToFloat(product.originalPrice)

    aux += aux * (surcharge / 100)
    objToAssign.surchargeApplied.value = surcharge
    objToAssign.surchargeApplied.type = type

    if (type === "ART") {
        objToAssign.inputSurcharge = surcharge
    } else {
        objToAssign.inputSurcharge = "0"
    }

    Object.assign(product, objToAssign)
    product.price = floatToPrice(aux);
}
function evaluateProductsSurcharges(products) {

    for (let item of products) {

        let userEditProductSurcharge = surcharges.value["ART"] && surcharges.value["ART"][item.id] ? true : false //If exist the item id in surcharges object, the user edited it
        let itemWithUrOwnSurcharge = userEditProductSurcharge || item.surchargeApplied.type === "ART" ? true : false

        if (!itemWithUrOwnSurcharge) {
            searchSurchargeProductCategory(item, true)
        } else if (userEditProductSurcharge) {

            if (surcharges.value["ART"][item.id].RECARGO === 0) {
                applyDefaultCategorySurcharge(item)
            } else {
                applySurcharge(item, surcharges.value["ART"][item.id].RECARGO, "ART")

            }
        }
    }

    return products;
}
function applyDefaultCategorySurcharge(product) {

    let fatherCategory, existCategorySurcharge;
    let categories = store.getters["categories/categories"]
    let actualSurcharge = store.getters["website/siteConfiguration"].surcharges || configuration.surcharges
    let productCategory = categories.find((category) => category.id === product.idCategoria)
    let surcharge, globalSurcharge;


    if (productCategory === undefined) {
        for (let category of categories) {
            if (category.childrens.length > 0) {
                productCategory = category.childrens.find((subcategory) => subcategory.id === product.idCategoria)

                if (productCategory !== undefined) {
                    fatherCategory = category
                    break;
                }
            }
        }
    }

    //Find if the default category surcharge of the product was also edited
    if (surcharges.value["CAT"] && surcharges.value["CAT"][productCategory.id]) {

        surcharge = surcharges.value["CAT"][productCategory.id]

    } else if (surcharges.value["CAT"] && surcharges.value["CAT"][fatherCategory.id]) {

        surcharge = surcharges.value["CAT"][fatherCategory.id]

    } else {
        //The product category was not edited, so i look for it in the default client configuration
        surcharge = actualSurcharge.find((element) => element.ID === productCategory.id && element.TIPO === "CAT")

        if (fatherCategory !== null && surcharge === undefined) {
            surcharge = actualSurcharge.find((element) => element.ID === fatherCategory.id && element.TIPO === "CAT")
        }

    }

    existCategorySurcharge = surcharge === undefined || surcharge.RECARGO === 0 ? false : true

    if (existCategorySurcharge) {
        applySurcharge(product, surcharge.RECARGO, "CAT")
    } else {
        //Doesnt exist any type of category surcharges, so apply global surcharge
        globalSurcharge = surcharges.value["TODOS"] ? surcharges.value["TODOS"]["-1"] : actualSurcharge.find((element) => element.TIPO === "TODOS")
        globalSurcharge = globalSurcharge === undefined ? 0 : globalSurcharge.RECARGO

        applySurcharge(product, globalSurcharge, "TODOS")

    }
}
function searchSurchargeProductCategory(product) {

    let userSurchargeApplied, productCategorySurcharge, isFather, productCategory, fatherCategory, fatherCategorySurcharge;
    let categories = store.getters["categories/categories"]
    let actualSurcharge = store.getters["website/siteConfiguration"].surcharges || configuration.surcharges //Surcharges saved in database
    let globalSurcharge;

    if (surcharges.value["CAT"]) {

        productCategorySurcharge = surcharges.value["CAT"][product.idCategoria]
        if (productCategorySurcharge) {
            //The product category surcharge was edited

            if (productCategorySurcharge?.validations.isError) {
                product.isError = true
                product.message = "La categoria a la cual pertenece este producto contiene errores"
            } else {
                product.isError = false
                product.message = ""

                userSurchargeApplied = productCategorySurcharge.RECARGO
                applySurcharge(product, userSurchargeApplied, "CAT")
            }

        } else {
            //Find father category and find his surcharge if it exists
            productCategory = categories.find((category) => category.id === product.idCategoria)
            isFather = productCategory === undefined ? false : true

            if (!isFather) {

                for (let category of categories) {
                    if (category.childrens.length > 0) {
                        productCategory = category.childrens.find((subcategory) => subcategory.id === product.idCategoria)

                        if (productCategory !== undefined) {
                            fatherCategory = category
                            break;
                        }
                    }
                }

                if (surcharges.value["CAT"][fatherCategory.id]) {
                    fatherCategorySurcharge = surcharges.value["CAT"][fatherCategory.id]

                    if (fatherCategorySurcharge.RECARGO !== 0 || fatherCategorySurcharge.validations.isError) {
                        applySurcharge(product, fatherCategorySurcharge.RECARGO, "CAT")
                    }


                } else {
                    applyDefaultCategorySurcharge(product)

                }

            }

            if (isFather) {
                //Doesnt exist any type of category surcharges, so apply global surcharge
                globalSurcharge = surcharges.value["TODOS"]["-1"] ? surcharges.value["TODOS"]["-1"] : actualSurcharge.find((element) => element.TIPO === "TODOS")
                globalSurcharge = globalSurcharge === undefined ? 0 : globalSurcharge.RECARGO

                applySurcharge(product, globalSurcharge, "TODOS")

            }

        }

    } else {
        applyDefaultCategorySurcharge(product)
    }
}

const emit = defineEmits(['reloadProducts']);
const navigateTo = () => router.push(goTo.value)
const priceToFloat = (price) => parseFloat(price.replace(/\./g, '').replace(/,/g, '.').replace(/[^0-9.]/g, ''))
const floatToPrice = (price) => parseFloat(price).toLocaleString('en-ES', { style: 'currency', currency: !store.getters["currency/currency"] ? "USD" : "ARS" })
const collectMarkups = ref((surchargeInformation, index) => {

    const regex = /^\d*\.?\d*$/;
    const actualSurcharge = store.getters["website/siteConfiguration"].surcharges || configuration.surcharges
    const product = getProduct(surchargeInformation.ID);
    const tag = surchargeInformation.TIPO
    let inputCategory = {};
    let metadata = {
        "row": "RECARGO",
        "input": surchargeInformation.RECARGO === "" || surchargeInformation.RECARGO === "0" ? 0 : surchargeInformation.RECARGO
    }

    if (!(tag in surcharges.value)) {
        surcharges.value[tag] = {}
    }

    let validations = {};
    let normalizePrice, floatPrice, globalSurcharge;


    if (!regex.test(surchargeInformation.RECARGO)) {

        if (tag === "ART") {
            product.message = "Solo numeros positivos; Para numeros con coma usar un punto (.) y NO una coma (,)"
            product.isError = true
            product.inputSurcharge = surchargeInformation.RECARGO
            product.surchargeApplied.value = surchargeInformation.RECARGO

            metadata.section = "Articulos"
            metadata.name = product.denominacion
        }

        if (tag === "CAT") {
            inputCategory.message = "Solo numeros positivos; Para numeros con coma usar un punto (.) y NO una coma (,)"
            inputCategory.isError = true
            inputCategory.surcharge = surchargeInformation.RECARGO

            metadata.name = inputCategory.name
            metadata.section = "Categorias"

            exposedInputCategory.value = Object.assign(exposedInputCategory.value, inputCategory)

        }

        if (tag === "TODOS") {
            metadata.name = ""
            metadata.section = "General"
        }


        //Synchronize with cloned array of surcharges
        validations.isError = true;
        validations.message = "Solo numeros positivos; Para numeros con coma usar un punto (.) y NO una coma (,)";
        surchargeInformation.metadata = metadata;
        surchargeInformation.validations = validations;
        surcharges.value[tag][index] = surchargeInformation;

        if (tag === "CAT") {
            productsRef.value.forEach((item) => searchSurchargeProductCategory(item)) //Set the first available position in the hierarchy
        }

    } else {

        surchargeInformation.RECARGO = surchargeInformation.RECARGO === "" || surchargeInformation.RECARGO === "0" ? 0 : surchargeInformation.RECARGO

        if (tag === "ART") {

            product.message = ""
            product.isError = false
            if (surcharges.value[tag][index] === undefined) {
                //Save only the first product surcharge value (default value) 
                metadata.previousValue = product.surchargeApplied.value
            }

            floatPrice = priceToFloat(product.originalPrice)

            if (surchargeInformation.RECARGO === 0) {
                //User left the field empty so i have to apply the first available position in the hierarchy
                product.inputSurcharge = "0"
                searchSurchargeProductCategory(product)

            } else {

                floatPrice += floatPrice * (surchargeInformation.RECARGO / 100)
                product.inputSurcharge = surchargeInformation.RECARGO
                product.surchargeApplied.value = surchargeInformation.RECARGO
                product.surchargeApplied.type = "ART"
                normalizePrice = floatToPrice(floatPrice)
                product.price = normalizePrice
            }


            metadata.section = "Articulos"
            metadata.name = product.denominacion

        }

        if (tag === "CAT") {

            if (surcharges.value[tag][index] === undefined) {
                //Save only the first category surcharge value (default value) 
                metadata.previousValue = "surcharge" in exposedInputCategory.value ? exposedInputCategory.value.surcharge : 0
            }

            inputCategory.message = ""
            inputCategory.isError = false
            inputCategory.surcharge = surchargeInformation.RECARGO

            exposedInputCategory.value = Object.assign(exposedInputCategory.value, inputCategory)

            metadata.section = "Categorias"
            metadata.name = exposedInputCategory.value.name
        }

        if (tag === "TODOS") {

            if (surcharges.value[tag][index] === undefined) {
                //Save only the first category surcharge value (default value) 
                globalSurcharge = actualSurcharge.find((element) => element.TIPO === "TODOS")
                globalSurcharge = globalSurcharge === undefined ? 0 : globalSurcharge.RECARGO

                metadata.previousValue = globalSurcharge
            }

            metadata.name = ""
            metadata.section = "General"
        }

        validations.isError = false;
        validations.message = "";

        surchargeInformation.validations = validations;
        if (surcharges.value[tag][index] === undefined) surcharges.value[tag][index] = { metadata: {} }

        if (surcharges.value[tag][index].metadata && 'previousValue' in surcharges.value[tag][index].metadata) {
            //Write previousValue property 
            metadata.previousValue = surcharges.value[tag][index].metadata.previousValue;
        }


        Object.assign(surcharges.value[tag][index], surchargeInformation)
        Object.assign(surcharges.value[tag][index].metadata, metadata)

        if (tag === "CAT" || tag === "TODOS") productsRef.value = evaluateProductsSurcharges(productsRef.value)
    }

    if (tag === "TODOS") {
        emitter.emit("handleTotalSurcharge", surchargeInformation)
    }


})

const emitter = getCurrentInstance()?.appContext.app.config.globalProperties.emitter;
const router = useRouter()
const store = useStore()
const props = defineProps({
    products: {
        type: Array
    },
    skeleton: {
        type: Boolean
    },
    filters: {
        type: Object
    },
    configuration: {
        type: Object
    }
})

let configuration;
let warning = ref(false)
let goTo = ref("")
let surcharges = ref({}) //Surcharges edited by user
let exposedInputCategory = ref({})
let productsRef = ref(dummyData("products", 30))

defineExpose({
    collectMarkups,
    exposedInputCategory,
})
onMounted(() => emitter.on('handleInput', (e) => collectMarkups.value(e, -1)))
onBeforeRouteLeave((to, from, next) => {

    goTo.value = to
    if ((store.getters["owner/isEdittingPrices"] || store.getters["owner/isEdittingInfo"]) && warning.value === false) {
        warning.value = true
    } else {

        if (from.path === "/productos" && store.getters["owner/isEdittingPrices"]) {
            store.dispatch(`owner/setPricesMode`, false)
        }

        if (from.path === "/" && store.getters["owner/isEdittingInfo"]) {
            store.dispatch(`owner/setInfoMode`, false)
        }

        warning.value = false
        next()
    }

})

onBeforeMount(() => configuration = store.getters["website/siteConfiguration"])
watch(() => props.products, () => {

    let products = props.products
    let filters = props.filters
    
    if (products[0].image !== "0.jpg") {
        productsRef.value = evaluateProductsSurcharges(products)

        if ("categoria" in filters) {
            exposedInputCategory.value = setCategoryInput(filters["categoria"])
        }
    }else{
        productsRef.value = products
    }

});

</script>