
// initial state
// shape: [{ id, quantity }]
const state = {
    currency: JSON.parse(localStorage.getItem("ars")) || false,
    dollar: 0
}

// getters
const getters = {
    currency: (state) => {
        return state.currency;
    },
    dollar: (state) => {
        return state.dollar;
    },
}

// actions
const actions = {
    setCurrency({ commit }, currency) {
        localStorage.setItem("ars", JSON.stringify(currency))
        commit("setCurrency", { currency })
    },
    setDollar({ commit }, dollar) {
        if(state.dollar !== dollar){
            commit("setDollar", { dollar })
        }
    }
}

// mutations
const mutations = {
    setCurrency(state, { currency }) {
        state.currency = currency
    },

    setDollar(state, { dollar }) {
        state.dollar = dollar
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
