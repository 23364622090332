
// initial state
// shape: [{ id, quantity }]
const state = {
    skeleton: true 
}

// getters
const getters = {
    skeleton: (state) => {
        return state.skeleton;
    }
}

// actions
const actions = {
    setSkeleton({ commit }, skeleton) {
        commit('owner/setSkeleton', { skeleton }, { root: true })
    }
}

// mutations
const mutations = {
    setSkeleton(state, { skeleton }) {
        state.skeleton = skeleton
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
