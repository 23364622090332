import Home from "@/views/HomeView.vue";
import Products from "@/views/ProductView.vue";
import Login from "@/views/LoginView.vue";
import PageNotFound from "@/views/PageNotFound.vue";


export default [{
  path: "/",
  component: Home,
},
{
  path: "/productos",
  component: Products,
  props: route => ({category: route.query.categoria, brand: route.query.marca, order: route.query.order, page: route.query.page, busqueda: route.query.busqueda})
},
{
  path: "/admin",
  component: Login,
},
{
  path: '/:catchAll(.*)',
  component: PageNotFound
}

];
