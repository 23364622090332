<template>
    <div class="collapse" :id="collapseId">
        <slot></slot>
    </div>
</template>

<script>
export default {
    props: {
        collapseId: String,
    },

};
</script>

<style scoped></style>