<template>

  <v-overlay class="align-center justify-center" v-model="overlay" opacity="0.9">
    <p style="color: #fff;font-size: 1.4rem;width: 20rem;font-weight: bold;text-align: center;">¡Lo siento!
      Actualmente, la sección de administración no está disponible en dispositivos móviles. Sin embargo,
      puedes acceder a ella fácilmente desde tu computadora. ¡Gracias por tu comprensión y paciencia!</p>
  </v-overlay>

  <div class="home">
    <v-skeleton-loader type="ossein" :loading="skeleton" height="355px">
      <div id="carousel-banners-home" class="carousel slide">
        <div class="carousel-inner" style="padding-bottom: unset;">

          <div class="carousel-item active">
            <img :src="bannerSource" title="Banner" class="d-block w-100" alt="Banner">
          </div>


        </div>
      </div>
    </v-skeleton-loader>

    <div class="slogan-container" v-if="!skeleton">
      <p v-if='!$store.getters["owner/isEdittingInfo"]' class="slogan text-shadow-drop-br">
        <strong>{{ slogan }}</strong>
      </p>
      <div v-else>
        <input type="text" @input="dataToTypeChange({ SLOGAN: $event.target.value, friendlyName: 'Slogan' })"
          class="slogan text-shadow-drop-br input-slogan" placeholder="Slogan" :value = "slogan"
          style="font-size: 1.6rem !important; font-weight: 500 !important;width:85rem;">
        <div v-show="sloganError" class="invalid-feedback-custom">
          Cant. maxima de caracteres: 70
        </div>
      </div>

    </div>

    <div class="container">

      <div id="carousel-feature-products" class="carousel products slide carousel-dark">

        <div class="carousel-header">
          <v-skeleton-loader type="text" :loading="skeleton" style="width:400px; background:none">
            <p class="carousel-title">PRODUCTOS DESTACADOS</p>
          </v-skeleton-loader>

          <div class="make-flexible buttons-navigator">
            <button class="carousel-control-prev products" type="button" data-bs-target="#carousel-feature-products"
              data-bs-slide="prev">
              <span class="carousel-control-prev-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Previous</span>
            </button>
            <button class="carousel-control-next products" type="button" data-bs-target="#carousel-feature-products"
              data-bs-slide="next">
              <span class="carousel-control-next-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Next</span>
            </button>
          </div>
        </div>

        <hr style="width:100%">

        <div class="carousel-inner">
          <div v-for="(article, index) in feturedProducts" :key="article.id" class="carousel-item"
            :class="{ active: index === 0 ? true : false }">
            <div class="make-flexible buttons-navigator">
              <ArticleComponent v-for="item in article" :key="item.id" :article="item" style="box-shadow: unset;"
                :skeleton="skeletonProducts" />
            </div>
          </div>
        </div>
      </div>

      <div v-if='store.getters["mobile/isMobile"]' class="card">
        <h5 class="card-header"><strong>CREE UNA CUENTA</strong></h5>
        <div class="card-body">
          <h5 class="card-title">Potencie al maximo sus compras</h5>
          <p class="card-text">Creando una cuenta en nuestra web podemos darte una atencion mas enfocada en tus
            necesidades</p>
          <router-link :to="{ path: '/registrarse' }" class="ty-btn ty-btn__primary w-50"> Registrarse </router-link>
        </div>
      </div>

      <div id="carousel-news-products" class="carousel products slide carousel-dark mt-4">

        <div class="carousel-header">
          <v-skeleton-loader type="text" :loading="skeleton" style="width:400px; background:none">
            <p class="carousel-title">PRODUCTOS NUEVOS</p>
          </v-skeleton-loader>

          <div class="make-flexible buttons-navigator">
            <button class="carousel-control-prev products" type="button" data-bs-target="#carousel-news-products"
              data-bs-slide="prev">
              <span class="carousel-control-prev-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Previous</span>
            </button>
            <button class="carousel-control-next products" type="button" data-bs-target="#carousel-news-products"
              data-bs-slide="next">
              <span class="carousel-control-next-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Next</span>
            </button>
          </div>

        </div>

        <hr style="width:100%">

        <div class="carousel-inner">
          <div v-for="(article, index) in newsProducts" :key="article.id" class="carousel-item"
            :class="{ active: index === 0 ? true : false }">
            <div class="make-flexible buttons-navigator">
              <ArticleComponent :renderMobile="false" v-for="item in article" :key="item.id" :article="item"
                :skeleton="skeletonProducts" />
            </div>
          </div>
        </div>


      </div>

      <div id="carousel-updated-products" class="carousel products slide carousel-dark mt-4">

        <div class="carousel-header">
          <v-skeleton-loader type="text" :loading="skeleton" style="width:400px; background:none">
            <p class="carousel-title">PRODUCTOS ACTUALIZADOS</p>
          </v-skeleton-loader>

          <div class="make-flexible buttons-navigator">
            <button class="carousel-control-prev products" type="button" data-bs-target="#carousel-updated-products"
              data-bs-slide="prev">
              <span class="carousel-control-prev-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Previous</span>
            </button>
            <button class="carousel-control-next products" type="button" data-bs-target="#carousel-updated-products"
              data-bs-slide="next">
              <span class="carousel-control-next-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Next</span>
            </button>
          </div>

        </div>

        <hr style="width:100%">

        <div class="carousel-inner">
          <div v-for="(article, index) in updatedProducts" :key="article.id" class="carousel-item"
            :class="{ active: index === 0 ? true : false }">
            <div class="make-flexible buttons-navigator">
              <ArticleComponent :renderMobile="false" v-for="item in article" :key="item.id" :article="item"
                :skeleton="skeletonProducts" />
            </div>
          </div>
        </div>


      </div>
    </div>

  </div>

  <SaveConfigurationComponent v-if="webConfiguration" :webConfiguration="webConfiguration" :skeleton="skeleton"
    @remove-surcharge-change="removeSurchargeChange" />
  <Warning :activatorProps="warning" @close-warning="(val) => warning = val" @continue-navigation="navigateTo" />

</template>

<style scoped>
@media (max-width: 1000px) {

  .slogan {
    margin: 0 auto;
  }

  .products {
    justify-content: center !important
  }

  .container {
    padding: unset !important;
  }

  #carousel-feature-products {
    border-radius: unset !important;
  }

  .carousel.products {
    padding: 1rem !important;
    margin: 3rem 0 !important
  }

  .make-flexible.buttons-navigator {
    gap: unset !important
  }

  .carousel-title {
    font-size: 1rem !important;
  }

}

.invalid-feedback-custom {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: var(--bs-form-invalid-color);
}

.carousel-inner {
  padding-bottom: 2rem;
}

input.slogan.text-shadow-drop-br.input-slogan:focus-visible {
  outline: none;
}

input[type="text"],
input[type="password"],
input[type="tel"],
input[type="number"],
textarea,
select {
  font-size: 1rem !important;
}

.input-slogan {
  height: unset !important;
  text-align: center !important;
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  background: none !important;
}

.slogan-container {
  margin: 3rem 0 !important;
  display: flex;
  justify-content: center;
}

.slogan {
  font-size: 1.6rem !important;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 4px;
  color: #4f4c4c;
}


.text-shadow-drop-br {
  -webkit-animation: text-shadow-drop-br 0.6s both;
  animation: text-shadow-drop-br 0.6s both;
}

@-webkit-keyframes text-shadow-drop-br {
  0% {
    text-shadow: 0 0 0 rgba(0, 0, 0, 0);
  }

  100% {
    text-shadow: 6px 6px 18px rgba(0, 0, 0, 0.35);
  }
}

@keyframes text-shadow-drop-br {
  0% {
    text-shadow: 0 0 0 rgba(0, 0, 0, 0);
  }

  100% {
    text-shadow: 6px 6px 18px rgba(0, 0, 0, 0.35);
  }
}


.save-button-home {
  margin-bottom: 5rem;
}

.v-btn__prepend {
  margin: unset !important;
  font-size: 1.5rem;
  color: #fff !important;
}

.products {
  gap: unset !important
}

.containers-cards {
  display: flex;
  gap: 4rem;
  margin: 3rem 0;
}

.card-header {
  background-color: #26323e !important;
  color: #fff !important;
}

.make-flexible {
  display: flex;
  gap: 1rem;
  justify-content: space-around;
}

.carousel-control-prev.products,
.carousel-control-next.products {
  position: unset !important;
  width: unset !important;
}

.carousel-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%
}

.carousel-title {
  margin: unset;
  font-weight: bold;
  font-size: 1.3rem;
}



#carousel-feature-products {
  background-color: #fff;
  padding: 2rem;
  border-radius: 1rem;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
</style>

<script setup>
// @ is an alias to /src
import { ref, onMounted, computed, watch, getCurrentInstance } from 'vue';
import { useRouter } from 'vue-router'
import ArticleComponent from "@/components/ArticleComponent.vue";
import SaveConfigurationComponent from "@/components/SaveConfigButton.vue";
import Warning from "@/components/BeforeLeaveWarning.vue";
import axios from "../services/axios.js";
import { dummyData } from "@/utils/utils.js";
import { useStore } from 'vuex';

async function fetchArticles() {
  try {
    skeletonProducts.value = true
    imagesLoaded = []
    const userMoneyPreference = store.getters["currency/currency"]
    const products = await axios.fetchData(`/home-products/?user_money_preference=${userMoneyPreference}`);

    for (let [key, value] of Object.entries(products)) {
      isFeatured.value = key === "featuredItems" ? true : false

      const len = value.length;
      const iterations = Math.ceil(len / carouselSize.value);

      let aux = [];

      for (let j = 0; j < iterations; j++) {
        const start = j * carouselSize.value;
        const end = Math.min(start + carouselSize.value, len);

        const subarray = value.slice(start, end);
        aux.push(subarray);
      }

      products[key] = aux;
    }

    feturedProducts.value = products.featuredItems;
    updatedProducts.value = products.lastUpdatedItems;
    newsProducts.value = products.newItems;

    skeleton.value = false
  } catch (error) {
    console.error("Error al obtener los productos:", error);
  }
};

function obtenerCantidadTotal(arr) {
  let count = 0;

  // Función recursiva para contar elementos
  function contarElementos(arr) {
    arr.forEach(element => {
      if (Array.isArray(element)) {
        // Si el elemento es un array, llamamos recursivamente a la función
        contarElementos(element);
      } else {
        // Si no es un array, incrementamos el contador
        count++;
      }
    });
  }

  // Llamamos a la función para contar los elementos
  contarElementos(arr);

  return count;
}

function dataToTypeChange(data) {

  if (!("Otros" in webConfiguration.value)) {
    webConfiguration.value["Otros"] = {}
  }


  let change = {}
  let metadata = {
    row: Object.keys(data)[0],
    input: Object.values(data)[0],
    name: Object.values(data)[1],
    section: "Otros"
  }
  let validations = {
    message: "",
    isError: false
  }
  let id = metadata.row

  if ("SLOGAN" in data) {
    validations.message = data.SLOGAN.length > 70 ? "Cant. maxima de caracteres: 70" : ""
    validations.isError = data.SLOGAN.length > 70 ? true : false
    sloganError.value = validations.isError 
  }

  change.metadata = metadata
  change.validations = validations

  delete data.friendlyName
  Object.assign(change, data)

  if (!(id in webConfiguration.value["Otros"])) {
    webConfiguration.value["Otros"][id] = { metadata: {} }
  }

  Object.assign(webConfiguration.value["Otros"][id], change)
  console.log("🚀 ~ dataToTypeChange ~ webConfiguration.value:", webConfiguration.value)

}

function removeSurchargeChange(data) {

  const identifier = data.type
  delete webConfiguration.value["Otros"][identifier]

  if (Object.entries(webConfiguration.value["Otros"]).length === 0) {
    delete webConfiguration.value["Otros"]
  }
}

//eslint-disable-next-line
let skeleton = ref(true)
let skeletonProducts = ref(true)
let feturedProducts = ref([dummyData("products", 4)]);
let updatedProducts = ref([dummyData("products", 5)]);
let newsProducts = ref([dummyData("products", 5)]);
let isFeatured = ref(false)
let slogan = ref("")
let bannerSource = ref("")
let warning = ref(false)
let sloganError = ref(false)
let goTo = false;
let imagesLoaded = []

const store = useStore();
const router = useRouter()
const emitter = getCurrentInstance()?.appContext.app.config.globalProperties.emitter;
const overlay = computed(() => store.getters["mobile/mobile"] && store.getters["owner/isEdittingInfo"])
const successfullyCharged = (id) => {
  imagesLoaded.push(id)
  let totalLen = obtenerCantidadTotal(feturedProducts.value) + obtenerCantidadTotal(newsProducts.value) + obtenerCantidadTotal(updatedProducts.value)
  if (imagesLoaded.length === totalLen) {
    skeletonProducts.value = false
  }
}
const carouselSize = computed(() => {

  let size;

  if (store.getters["mobile/mobile"]) {
    size = 1;
  } else if (isFeatured.value) {
    size = 4
  } else {
    size = 5
  }

  return size;

})

const navigateTo = () => router.push(goTo);
const webConfiguration = ref({})

watch(carouselSize, () => fetchArticles());
store.subscribe(async (mutation) => {

  if (mutation.type === "currency/setCurrency") {
    await fetchArticles()
    skeletonProducts.value = false
  }

  if (mutation.type === "website/setConfiguration") {

    slogan.value = store.getters['website/siteConfiguration']?.SLOGAN
    if (bannerSource.value === "") {
      fetch(`/portal.php?PATH=${store.getters['website/siteConfiguration'].BANNERS[0]}`)
        .then((response) => {

          if (!response.ok) {
            throw new Error("Error al cargar la imagen")
          }

          return response.blob()
        })
        .then((blob) => {
          bannerSource.value = URL.createObjectURL(blob)
        })
        .catch((error) => {
          console.log("🚀 ~ onMounted ~ error:", error)
        })
    }
  }
})


onMounted(() => {

  if (store.getters['website/siteConfiguration'].SLOGAN) {
    slogan.value = store.getters['website/siteConfiguration']?.SLOGAN
  }

  fetchArticles()

  emitter.on('phoneChange', (e) => dataToTypeChange(e));
  emitter.on('addressChange', (e) => dataToTypeChange(e));
  emitter.on('mailChange', (e) => dataToTypeChange(e));
  emitter.on('imageLoaded', async (e) => successfullyCharged(e))


});
</script>
