
// initial state
// shape: [{ token, quantity }]
const state = {
    logged: JSON.parse(localStorage.getItem("logged")) || false,
    token: localStorage.getItem("token") || null
}

// getters
const getters = {
  token: (state) => {
    return state.token
  },

  isLoggin: (state) => {
    return state.logged
  }
}

// actions
const actions = {
  login ({ commit }, {login, token}) {
    commit("setLogin", { login });
    commit("setToken", { token });
    localStorage.setItem("logged", JSON.stringify(login));
  },

  logout ({ commit }) {
    commit("setLogin", { login: false })
    localStorage.removeItem("logged"); 
  }
}

// mutations
const mutations = {
    setLogin (state, { login }) {
    state.logged = login
  },

  setToken (state, { token }) {
    state.token = token

  }

}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
