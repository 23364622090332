import { createApp } from "vue";
import App from "./App.vue";
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import router from "./router/index";
import store from "./store";
import './assets/styles.css'
import './assets/standalone.css'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap';
import 'bootstrap-icons/font/bootstrap-icons.css';
import mitt from 'mitt'

const emitter = mitt()
const vuetify = createVuetify({
    components,
    directives,
  })

store.dispatch('mobile/initMobileState');
const app = createApp(App)

app.config.globalProperties.emitter = emitter
app.use(store).use(router).use(vuetify).mount("#app");