import { createStore, createLogger } from 'vuex'
import mobile from "./modules/mobile";
import currency from "./modules/currency";
import cart from "./modules/cart.js";
import login from "./modules/logged";
import categories from "./modules/categories.js";
import owner from "./modules/owner.js";
import website from "./modules/website.js";
import skeleton from "./modules/skeleton.js";

const debug = process.env.NODE_ENV !== 'production'

export default createStore({
  modules: {
    cart,
    login,
    mobile,
    currency,
    categories,
    owner,
    website,
    skeleton
  },
  strict: debug,
  plugins: debug ? [createLogger()] : []
})
