
// initial state
// shape: [{ id, quantity }]
const state = {
    configuration: {},
}

// getters
const getters = {
    siteConfiguration: (state) => {
        return state.configuration;
    },
}

// actions
const actions = {
    setConfiguration({ commit }, configuration) {
        commit('website/setConfiguration', { configuration }, { root: true })
    }

}

// mutations
const mutations = {
    setConfiguration(state, { configuration }) {
        state.configuration = configuration
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
