<script>
import Header from "@/components/SiteHeader.vue";
import Footer from "@/components/SiteFooter.vue";

export default {
  components: {
    Header,
    Footer,
  },
  computed: {
    isRouteAdmin() {
      return this.$route.path === '/admin';
    }
  }
};
</script>

<template>
  <v-app class="application">
    <Header v-show="!isRouteAdmin" />
    <main>
      <RouterView />
    </main>
    <Footer v-show="!isRouteAdmin" />
  </v-app>
</template>

<style>
body {
  opacity: 1;
  background-color: rgb(248, 249, 250, 1) !important;
}

.v-application__wrap {
  display: flex !important;
  flex-direction: column !important;
  justify-content: space-between !important;
}

main {
  flex-grow: 1;
  /* Hace que ocupe todo el espacio disponible */
}

nav,
footer {
  flex-shrink: 0;
  /* Evita que nav y footer se reduzcan en tamaño */
}

.footer {
  position: sticky;
  bottom: 0;
  width: 100%;
  background-color: #333;
  color: white;
  padding: 20px 0;
  text-align: center;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}
</style>
