
// initial state
// shape: [{ id, quantity }]
const state = {
    mobile: window.innerWidth < 1000,
    windowsSize: window.innerWidth
}

// getters
const getters = {
    mobile: (state) => {
        return state.mobile
    },
    isNotMobile: (state) => {
        return !state.mobile
    }
}

// actions
const actions = {
    initMobileState({ commit }) {

        window.addEventListener('resize', () => {
            let mobile = window.innerWidth < 1000
            let windowsSize = window.innerWidth

            commit("setMobile", { mobile })
            commit("setWindowsSize", { windowsSize })

        });
    }
}

// mutations
const mutations = {
    setMobile(state, { mobile }) {
        state.mobile = mobile
    },

    setWindowsSize(state, { windowsSize }) {
        state.windowsSize = windowsSize
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
