<style>
@media (max-width: 1000px) {

    .form {
        width: 90% !important;
    }

    .actions-container {
        display: flex;
        flex-direction: column;
    }
}

.form {
    min-width: 35%;
    background: #fff;
    padding: 2rem;
    border-radius: 1rem;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.actions-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 2rem;
    height: 100%;
    flex-wrap: wrap;
}

.button-action {
    height: 7rem !important;
    width: 7rem;
    font-size: 3rem;
}

.select-option-step {
    display: flex;
    align-items: center;
    justify-content: center;
}

.select-option-step.step2 {
    height: 80%;
    flex-direction: column;
}

.agroup-buttons {
    display: flex;
    gap: 1rem;
}

.v-data-table-header__content>span {
    font-weight: bold;
}

.isDisplayingDatatable {
    height: 40rem;
    width: 60rem;
}

.isDisplayingMenu {
    height: 35rem;
}

.v-btn__content {
    text-wrap: wrap !important;
}

.actions-section-one {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    height: 80%;
    justify-content: center;
}
</style>

<template style="background: #F2F2F2;">

    <div v-if="sessionExpired" style="margin: unset; font-size: 1rem;" class="alert alert-warning" role="alert">
        Su sesion ha expirado. Porfavor, vuelva a iniciar sesion para continuar
    </div>

    <div class="container" style="display: flex;justify-content: center;align-items: center;height: 100vh;">

        <div class="form"
            :class="{ isDisplayingDatatable: step === 3 ? true : false, isDisplayingMenu: step === 2 ? true : false }">
            <img src="https://supersistemasweb.com/acext/icons/mihard.webp" alt="mihard logo">

            <v-window v-model="step" style="height: 100%;">

                <v-window-item :value="1">
                    <h1>Bienvenido !</h1>
                    <p>Sistema de administracion mi hard</p>
                    <Login />

                    <a href="https://api.whatsapp.com/send/?phone=5491122828112&text&app_absent=0">¿No podes acceder?
                        Ayuda</a>

                </v-window-item>

                <v-window-item class="select-option-step step2" :value="2">

                    <div class="actions-container">
                        <div class="actions-section-one">
                            <div class="agroup-buttons">
                                <v-btn class="button-action" elevation="4" style="font-size:3rem;"
                                    @click='initEditMode("Prices", "/productos")'>
                                    <i class="bi bi-coin" style="pointer-events: none;"></i>
                                </v-btn>
                                <v-btn class="button-action" elevation="4" style="font-size:3rem;"
                                    @click='initEditMode("Info", "/")'>
                                    <i class="bi bi-journals" style="pointer-events: none;"></i>
                                </v-btn>
                            </div>

                            <v-btn color="#4fbe31" elevation="4" @click="step = 3">
                                Ver recargos aplicados >
                            </v-btn>
                        </div>
                    </div>

                    <v-btn size="x-small" color="red-darken-1" elevation="4" @click="dialog = true"
                        style="width: 10rem;padding: 0.5rem 1rem;height: unset !important;" v-if="existSurcharges">
                        Eliminar todos los recargos aplicados
                    </v-btn>

                </v-window-item>

                <v-window-item class="select-option-step" :value="3">
                    <div style="display: flex; flex-direction: column;align-items: center;width: 100%;">
                        <v-data-table-server color="grey-darken-4" v-model:items-per-page="itemsPerPage"
                            :headers="headers" :items="serverItems" :items-length="totalItems" :loading="loadingTable"
                            :items-per-page-options="itemsPerPageMenu" item-value="name" next-icon="bi bi-chevron-right"
                            prev-icon="bi bi-chevron-left" last-icon="bi bi-chevron-double-right"
                            first-icon="bi bi-chevron-double-left" style="height: 31rem;overflow-y: scroll !important;"
                            @update:options="getSurcharges"></v-data-table-server>

                        <v-btn color="#4fbe31" elevation="4" @click="step = 2">
                            Volver
                        </v-btn>
                    </div>
                </v-window-item>

            </v-window>


            <v-dialog v-model="dialog" width="auto">
                <v-sheet :max-height="500" :width="450">

                    <div class="modal-header">
                        <p style="font-size: 1.4rem;font-weight: 500;margin:0 2rem;color:#fff">¡Atención!</p>
                    </div>

                    <v-window v-model="stepWarning" style="height: 100%;">

                        <v-window-item :value="1">

                            <p style="font-size: 1rem;margin:0 2rem;">Realizar esta accion provocara que todos los
                                recargos aplicados a los precios sean reseteados a 0 (cero)</p>

                            <div class="modal-footer">
                                <v-btn height="40px" color="red-darken-2" rounded="0" style="margin: 1rem !important;"
                                    @click="dialog = false" class="ms-auto save-modal-admin" :loading="loading"
                                    text="Cancelar"></v-btn>
                                <v-btn height="40px" rounded="0" style="margin: 1rem !important;"
                                    @click="stepWarning = 2" class="ms-auto save-modal-admin" :loading="loading"
                                    text="Aceptar"></v-btn>
                            </div>

                        </v-window-item>

                        <v-window-item :value="2">
                            <SuccessAlert v-if="sucessfully" @close-modal="() => dialog = false" hgt="250"
                                message="Los cambios ya se pueden ver reflejados en la web" />
                            <div v-else>
                                <p style="font-size: 1rem;margin:0 2rem;">¿Esta seguro de borrar todos los recargos
                                    aplicados actualmente?</p>
                                <div class="modal-footer">
                                    <v-btn height="40px" color="red-darken-2" rounded="0"
                                        style="margin: 1rem !important;" @click="closeWarnModal()"
                                        class="ms-auto save-modal-admin" :loading="loading" text="Cancelar"></v-btn>
                                    <v-btn height="40px" rounded="0" style="margin: 1rem !important;"
                                        @click="deleteSurchargues()" class="ms-auto save-modal-admin" :loading="loading"
                                        text="Borrar"></v-btn>
                                </div>
                            </div>
                        </v-window-item>


                    </v-window>

                </v-sheet>
            </v-dialog>

        </div>
    </div>

</template>

<script setup>

import Login from '@/components/LoginFormComponent.vue';
import { ref } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import SuccessAlert from '@/components/SuccessfullyAlert.vue'
import axios from "../services/axios.js";

function initEditMode(mode, url) {

    store.dispatch(`owner/set${mode}Mode`, true)
    router.push({ path: url })
}

async function deleteSurchargues() {
    loading.value = true

    try {
        await axios.postData("/admin/eliminar-recargos")
        sucessfully.value = true
        existSurcharges.value = false
        serverItems.value = []

    } catch (e) {
        console.log("🚀 ~ save ~ e:", e)

    } finally {
        loading.value = false
    }
}

//eslint-disable-next-line
async function getSurcharges({ page, itemsPerPage, sortBy }) {

    const surcharges = store.getters["website/siteConfiguration"].surcharges

    try {
        let url = new URL(`${window.location.origin}/admin/obtener-info-recargos`)

        url.searchParams.set("itemsPerPage", itemsPerPage)
        url.searchParams.set("page", page)

        const response = await axios.fetchData(url.toString().replace(window.location.origin, ""))
        console.log("🚀 ~ serverItems.value=response.data.map ~ response.data:", response.data)
        serverItems.value = response.data.map((element) => {
            return {
                tipo: element.TIPO === "CAT" ? "Categoria" : element.TIPO === "ART" ? "Articulo" : "Todos los productos",
                recargo: `${element.RECARGO}%`,
                denominacion: element.DENOMINACION,
                precio: element.PRECIO ? element.PRECIO : "-"
            }
        })

        totalItems.value = surcharges.length
        loadingTable.value = false
    } catch (error) {
        console.log(error)

    }
}

function closeWarnModal() {
    dialog.value = false
    stepWarning.value = 1
}

const store = useStore()
const router = useRouter()
const sessionExpired = store.getters["login/token"] === false ? true : false

let actualStep = store.getters["login/isLoggin"] && store.getters["owner/isOwner"] ? 2 : 1
let step = ref(actualStep)
let stepWarning = ref(1)
let sucessfully = ref(false)
let dialog = ref(false)
let loading = ref(false)
let itemsPerPage = ref(5)
let itemsPerPageMenu = ref([
    { value: 5, title: '5' },
    { value: 10, title: '10' },
    { value: 25, title: '25' },
    { value: 50, title: '50' },
    { value: 100, title: '100' },
    { value: -1, title: "Mostrar todos" }
])
let headers = ref([
    {
        title: "TIPO",
        align: 'start',
        sortable: false,
        key: "tipo"
    },
    {
        title: "RECARGO",
        align: 'end',
        sortable: false,
        key: "recargo"
    },
    {
        title: "PRODUCTO / CATEGORIA",
        align: 'end',
        sortable: false,
        key: "denominacion"
    },
    {
        title: "PRECIO FINAL",
        align: 'end',
        sortable: false,
        key: "precio"
    }

])
let existSurcharges = ref(false)
let serverItems = ref([])
let totalItems = ref(0)
let loadingTable = ref(true)

store.subscribe(async (mutation) => {

    if (mutation.type === "owner/setOwner") {
        if (store.getters["login/isLoggin"]) {
            step.value++
        }
    }

    if (mutation.type === "website/setConfiguration") {
        let config = store.getters["website/siteConfiguration"]
        if ("surcharges" in config) {
            if (config.surcharges.length > 0) {
                existSurcharges.value = true
            } else {
                existSurcharges.value = false
            }
        } else {
            existSurcharges.value = false

        }

    }

})

</script>
