import { createWebHistory, createRouter } from 'vue-router'
import mainRoutes from './main'
import store from '@/store/index.js';
import axios from "../services/axios.js";

const routes = mainRoutes

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

router.beforeEach((to, from, next) => {


  if(Object.entries(store.getters["website/siteConfiguration"]).length === 0 ){
    axios.fetchData('/obtener-configuracion');
  }

  next()
})

export default router;
