<style>
@media (max-width: 1600px) {
  .markup-error {
    width: 40% !important;
  }

  .nav {
    padding: unset !important
  }
}

@media (max-width: 1000px) {

  .subcategory {
    color: #9b9b9b;
  }
}

.mobile-catalogue-button {
  background: #26323E !important;
  border-radius: unset !important;
}

.subcategory {
  font-weight: unset !important;
}

.dropdown-exchange-rate {
  display: flex;
  align-items: center;
  gap: .5rem;
}

.dropdown-item.exchange-rate {
  display: flex;
  align-items: center;
  gap: .5rem;
}

.nav-section {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.nav-section.section-one {
  width: 70%;
  justify-content: flex-start;
  gap: 6rem;
}

.card-account-info {
  background-color: unset;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: .5rem;
}

.account-info-menu {
  width: 220px;
  position: absolute;
  inset: 0px 0px auto auto !important;
  margin: 0px !important;
  transform: translate(0px, 40px);
}

.nav-link:focus,
.nav-link:hover {
  color: #9fa1a4 !important;
}

.navbar {
  padding: unset !important;
  position: sticky;
  top: 0;
  background-color: #fff;
  width: 100%;
  z-index: 1050;
}

.navbar-nav {
  width: 100%;
  justify-content: space-around;
}

.navbar-collapse {
  width: 100%;
  background-color: #26323e;
}

.container-fluid {
  flex-wrap: wrap !important;
  justify-content: space-around !important;
}

.shopping-cart.active {
  background: #4fbe31 !important;
}

.shopping-cart {
  background: #9fb2c0 !important;
}

.cart-container {
  display: flex;
  gap: .5rem;
  margin-right: 1rem;
}

.bar {
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
}

.btn-search i,
.navbar-toggler span,
.exchange-rate-button img,
.exchange-rate-button span,
.style-account-button i {
  pointer-events: none;
}

.list-group-item.exchange-rate {
  display: flex;
  align-items: center;
}


.exchange-rate-button,
.style-account-button {
  background: none;
  border: none;
  color: #26323e;
  font-size: 1rem;
  font-weight: bold;
  display: flex;
  align-items: center;
  gap: .3rem;
}

.search-container {
  display: flex;
  align-items: center;
  gap: .5rem;
}

.slideInTop {
  -webkit-animation: slideInTop 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  animation: slideInTop 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

@-webkit-keyframes slideInTop {
  0% {
    -webkit-transform: translateY(-1000px);
    transform: translateY(-1000px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slideInTop {
  0% {
    -webkit-transform: translateY(-1000px);
    transform: translateY(-1000px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

.v-sheet.v-theme--light.rounded-0 {
  padding: 1rem;
  border-radius: 1rem !important;
}

.dialog-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dialog-header>button {
  margin-bottom: 1rem;
}

.catalogue-item {
  text-align: left;
}

.markup-error {
  width: 20%;
  color: red;
  font-size: .7rem;
}
</style>

<template>


  <nav class="navbar navbar-expand-lg bg-body-tertiary header-component">

    <div v-if='$store.getters["mobile/mobile"]' class="container-fluid" style="padding: unset !important;">
      <v-skeleton-loader type="button" :loading="skeleton" style="background:none; width:176px">
        <router-link :to="{ path: '/' }" class="navbar-brand">
          <img :src="logo" width="176" height="42" />
        </router-link>
      </v-skeleton-loader>

      <div class="bar">
        <button class="navbar-toggler button" style="padding: unset;color: #fff;" type="button"
          @click="drawer = !drawer">
          <span class="navbar-toggler-icon bar-icon" style="color: #fff !important;"></span>
        </button>

        <p class="dolar-value" style="font-weight: bold;">DOLAR: {{ $store.getters["currency/dollar"] }}</p>

        <div>
          <button class="exchange-rate-button" type="button" data-bs-toggle="collapse"
            data-bs-target="#collpase-exchange-rate" aria-expanded="false" aria-controls="collpase-exchange-rate">

            <img v-if='$store.getters["currency/currency"]'
              src="https://cdn-icons-png.flaticon.com/512/7826/7826245.png" width="20px" alt="argentina">
            <img v-else src="https://cdn-icons-png.flaticon.com/512/197/197484.png" width="20px" alt="estados unidos">

            <span style="font-size:.6rem;">&#9660;</span>
          </button>
        </div>


      </div>

      <v-navigation-drawer color="#26323e" v-model="drawer">

        <button class="remove-default-btn-styles" style="float: right;" @click="drawer = false">
          <i class="bi bi-x" style="color:#fff;font-size:1.5rem;padding-right: 1rem;"></i>
        </button>

        <ul class="navbar-nav me-auto mb-2 mb-lg-0" style="text-align:left">

          <li class="nav-item">
            <router-link :to="{ path: '/' }" class="nav-link">
              <span>Home</span>
              <i class="bi bi-chevron-right" style="padding-right: 1rem;"></i>
            </router-link>
          </li>

          <hr style="color: #fff;">


          <li class="nav-item">
            <router-link :to="{ path: '/productos' }" class="nav-link">
              <span>Ver todos los productos</span>
              <i class="bi bi-chevron-right" style="padding-right: 1rem;"></i>
            </router-link>
          </li>

          <hr style="color: #fff;">

          <li class="nav-item">

            <v-expansion-panels>
              <v-expansion-panel elevation="0" collapse-icon="bi bi-chevron-right">
                <v-expansion-panel-title class="nav-link mobile-catalogue-button">
                  <span>Catalogo</span>
                </v-expansion-panel-title>
                <v-expansion-panel-text style="background: #26323E !important;">

                  <div class="catalogue-container">
                    <div v-for='element in $store.getters["categories/categories"]' :key="element.id"
                      class="catalogue-item">

                      <router-link :to="{ path: '/productos', query: { categoria: element.url } }" rel="nofollow"
                        class="subcategory" style="color:#fff;">{{ element.name }}</router-link>

                      <ul>
                        <li v-for="category in element.childrens" :key="category.id">
                          <router-link :to="{ path: '/productos', query: { categoria: category.url } }" rel="nofollow"
                            class="subcategory">{{ category.name }}</router-link>
                        </li>
                      </ul>

                    </div>
                  </div>
                </v-expansion-panel-text>
              </v-expansion-panel>
            </v-expansion-panels>



          </li>


        </ul>
      </v-navigation-drawer>

      <BootstrapCollapse collapse-id="collpase-exchange-rate" style="width:100%"
        :class="{ show: activeCollapse === 1 }">

        <ul class="list-group">
          <li class="list-group-item exchange-rate">
            <button @click="$store.dispatch('currency/setCurrency', false)" class="dropdown-item exchange-rate" href="">
              <img src="https://cdn-icons-png.flaticon.com/512/197/197484.png" width="24px" alt="">
              Mostrar precios en dolares
            </button>
          </li>

          <li class="list-group-item exchange-rate">
            <button @click="$store.dispatch('currency/setCurrency', true)" class="dropdown-item exchange-rate" href="">
              <img src="https://cdn-icons-png.flaticon.com/512/7826/7826245.png" width="24px" alt="">Mostrar precios
              en pesos
            </button>
          </li>
        </ul>
      </BootstrapCollapse>


    </div>

    <div v-else class="container-fluid" style="padding: unset !important;">
      <div class="nav-section section-one">
        <v-skeleton-loader type="button" :loading="skeleton" style="background:none; width:176px">
          <router-link :to="{ path: '/' }" class="navbar-brand">
            <img :src="logo" width="176" height="42" />
          </router-link>
        </v-skeleton-loader>

        <div v-if='$store.getters["owner/isEdittingPrices"]' class="input-group mb-3" style="margin:unset !important; width: 15%;">
          <input style="height: unset !important;" type="text" class="form-control" placeholder="Markup general"
            :value="inputGeneralSurchageValue" @input="emitInputValue($event.target.value)">
          <span class="input-group-text">%</span>
          <span class="markup-error" :v-if="totalSurcharge.isError">{{ totalSurcharge.message
            }}</span>
        </div>

        <div v-else class="search-container" style="width:70%;">
          <form class="search-container" method="GET" action="/productos/" id="search-form" style="width:100%;">
            <div style="width: 100%;">
              <input name="busqueda" type="text" class="form-control" placeholder="Busqueda"
                style="height:2.5rem;padding:0 1rem;" v-model="search">
            </div>

            <router-link :to="{ path: routerLinkSearch }" class="btn btn-search"
              style="background: #ff5319;color: white;font-size: 1.2rem;">
              <i class="bi bi-search"></i>

            </router-link>
          </form>
        </div>
      </div>

      <div class="nav-section section-two">

        <div class="bar">
          <button class="navbar-toggler button" style="padding: unset;" type="button" data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
            aria-label="Toggle navigation">
            <span class="navbar-toggler-icon bar-icon"></span>
          </button>

          <div>
            <div class="dropdown">
              <button class="style-account-button dropdown-toggle" type="button" data-bs-toggle="dropdown"
                aria-expanded="false">

                <div v-if="ars" class="dropdown-exchange-rate">
                  <img src="https://cdn-icons-png.flaticon.com/512/7826/7826245.png" width="20px" alt="argentina">
                  <span>ARS</span>
                </div>

                <div v-else class="dropdown-exchange-rate">
                  <img src="https://cdn-icons-png.flaticon.com/512/197/197484.png" width="20px" alt="estados unidos">
                  <span>USD</span>
                </div>

              </button>

              <ul class="dropdown-menu" style="padding: 1rem;">
                <li class="list-group-item exchange-rate">
                  <button @click="$store.dispatch('currency/setCurrency', false)" class="dropdown-item exchange-rate">
                    <img src="https://cdn-icons-png.flaticon.com/512/197/197484.png" width="20px"
                      alt="estados unidos">Mostrar precios en dolares
                  </button>
                </li>

                <li class="list-group-item exchange-rate">
                  <button @click="$store.dispatch('currency/setCurrency', true)" class="dropdown-item exchange-rate">
                    <img src="https://cdn-icons-png.flaticon.com/512/7826/7826245.png" width="20px"
                      alt="estados unidos">Mostrar precios en pesos
                  </button>
                </li>
              </ul>
            </div>

          </div>

          <p class="dolar-value" style="font-weight: bold;">DOLAR: {{ $store.getters["currency/dollar"] }}</p>

        </div>

      </div>

      <div class="navbar-collapse" style="padding-left: 1rem;">
        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
          <li class="nav-item">
            <router-link :to="{ path: '/' }" class="nav-link">
              <span>Home</span>
            </router-link>
          </li>

          <li class="nav-item">
            <button class="nav-link" @click="changeCatalogueStatus">Catalogo</button>
          </li>

          <li class="nav-item">
            <router-link :to="{ path: '/productos' }" class="nav-link">
              <span>Ver todos los productos</span>
            </router-link>
          </li>

        </ul>

      </div>

      <v-expansion-panels v-model="showCatalogue">
        <v-expansion-panel value="catalogue">
          <v-expansion-panel-text style="padding: unset !important;">
            <div class="catalogue-container">
              <div v-for='element in $store.getters["categories/categories"]' :key="element" class="catalogue-item">


                <button v-if="$store.getters['owner/isEdditingPrices']" data-key="categoria" :data-filter="element.url"
                  @click="emitCategory" class="category-name">{{ element.name }}</button>

                <router-link v-else :to="{ path: '/productos', query: { categoria: element.url } }" rel="nofollow"
                  class="subcategory-name">{{ element.name }}</router-link>

                <ul>
                  <li v-for="category in element.childrens" :key="category.id">
                    <button v-if="$store.getters['owner/isEdditingPrices']" data-key="categoria"
                      :data-filter="category.url" @click="emitCategory" class="subcategory-name">{{ category.name
                      }}</button>
                    <router-link v-else :to="{ path: '/productos', query: { categoria: category.url } }" rel="nofollow"
                      class="subcategory">{{ category.name }}</router-link>
                  </li>
                </ul>

              </div>
            </div>
          </v-expansion-panel-text>

        </v-expansion-panel>
      </v-expansion-panels>
    </div>

    <v-dialog :max-width='$store.getters["mobile/mobile"] ? 700 : 500' v-if='!$store.getters["login/isLoggedin"]'
      v-model="isActive" style="padding: 1rem;">
      <v-sheet color="#fff" height="100%" tile>

        <div class="dialog-header">

          <div class="make-flexible">
            <button v-if="step > 1" @click="step--" style="margin-bottom: 1rem;"><i
                class="bi bi-arrow-left-circle-fill"></i></button>
            <p style="font-weight: bold;font-size: 1rem;">{{ title }}</p>
          </div>

          <button><i class="bi bi-x"></i></button>

        </div>
        <v-window v-model="step">

          <v-window-item :value="1">
            <Login />
          </v-window-item>

          <v-window-item :value="2">

            <form v-if="!existAccount" @submit="mailValidation" @submit.prevent="onSubmit">
              <div class="form-floating mb-3">
                <input type="email" class="form-control" name="email" v-bind="emailAttrs" v-model="email">
                <label for="correo">Email</label>
                <span style="color:red;">{{ errors.email }}</span>
              </div>
              <button class="ty-btn ty-btn__primary" :disabled="isSubmitting">{{ isSubmitting ? 'CARGANDO...' :
                'ENVIAR' }}</button>
            </form>

            <div v-else>
              <Login />
            </div>
          </v-window-item>



        </v-window>

        <button v-if="step === 1" class="a-modal" style="margin: 1rem auto;" @click="updateState(true)">¿Aun no
          tiene
          cuenta? Registrese</button>
        <button v-if="step === 1" class="a-modal" style="margin: 1rem auto;" @click="updateState(false)">Olvide mi
          contraseña</button>

      </v-sheet>
    </v-dialog>


  </nav>

</template>

<script setup>
import BootstrapCollapse from '@/components/BootstrapCollapse.vue';
import Login from '@/components/LoginFormComponent.vue';
import { useStore } from 'vuex';
import { ref, watch, computed, defineModel, onMounted, getCurrentInstance } from 'vue';
import { useForm } from 'vee-validate';
import { useRoute } from 'vue-router';
import schemas from "../utils/validations.js";
import axios from "../services/axios.js";

function updateState(isCreatingAccount) {
  step.value++
  isCreatingAccountState.value = isCreatingAccount
}

function loadLogo(logoPath) {
  console.log("🚀 ~ loadLogo ~ logoPath:", logoPath)
  fetch(`/portal.php?PATH=${logoPath}`)
    .then((response) => {

      if (!response.ok) {
        throw new Error("Error al cargar la imagen")
      }

      return response.blob()
    })
    .then((blob) => {
      logo.value = URL.createObjectURL(blob)
      document.querySelector('link[rel="icon"]').href = logo.value;
    })
    .catch((error) => {
      console.log("🚀 ~ onMounted ~ error:", error)
    })
}

let showCatalogue = ref([])
let existAccount = ref(false)
let isCreatingAccountState = ref(false)
let step = ref(1)
let drawer = ref(false)
let group = ref(null);
let isActive = ref(false)
let totalSurcharge = ref({ isError: false, message: "" })
let logo = ref("")
let skeleton = ref(true)

const search = defineModel()
const route = useRoute()
const store = useStore();
const routerLinkSearch = computed(() => `/productos/?busqueda=${encodeURIComponent(search.value)}`)
const ars = computed(() => store.getters["currency/currency"])
const { errors, handleSubmit, isSubmitting, defineField } = useForm({ validationSchema: schemas.email });
const [email, emailAttrs] = defineField('email');
const inputGeneralSurchageValue = ref(0)
const emitter = getCurrentInstance()?.appContext.app.config.globalProperties.emitter;
const emitInputValue = (value) => emitter.emit("handleInput", { RECARGO: value, ID: "-1", TIPO: 'TODOS' })
const emitCategory = (event) => emitter.emit("category", event)
const mailValidation = handleSubmit((values, actions) => { actions.setFieldError("email", "") }, ({ errors }) => console.log("🚀 ~ errors:", errors));
const changeCatalogueStatus = () => {
  let index = showCatalogue.value.findIndex((element) => element === "catalogue")
  let isActive = index === -1 ? false : true
  if (!isActive) showCatalogue.value.push("catalogue")
  if (isActive) showCatalogue.value.splice(index, 1)
}
const title = computed(() => {

  let text;

  if (step.value === 1) {
    text = "INICIAR SESION"
  } else if (step.value === 2) {
    text = isCreatingAccountState.value ? "INGRESE SU EMAIL" : "RECUPERAR CONTRASEÑA"
  }

  return text;


})

emitter.on('handleTotalSurcharge', (e) => {
  totalSurcharge.value.isError = e.validations.isError
  totalSurcharge.value.message = e.validations.message
  inputGeneralSurchageValue.value = e.RECARGO
});

onMounted(async () => {

  try {
    let response = await axios.getData("/usd")
    let data = response.data
    store.dispatch("currency/setDollar", data.dollar)

    if (store.getters['website/siteConfiguration'] && store.getters['website/siteConfiguration']?.LOGO){
      loadLogo(store.getters['website/siteConfiguration'].LOGO)
    }

  } catch (error) {
    console.log("🚀 ~ onMounted ~ error:", error)
  }

})

store.subscribe((mutation) => {

  if (mutation.type === "website/setConfiguration") {
    window.document.title = store.getters["website/siteConfiguration"].NOMBRE
    skeleton.value = false

    if (logo.value === "") {
      loadLogo(store.getters['website/siteConfiguration'].LOGO)
    }
  }
})


watch(group, () => drawer.value = false)
watch(route, () => {
  if (showCatalogue.value.findIndex((element) => element === "catalogue") !== -1) changeCatalogueStatus()
})



</script>
