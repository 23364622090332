<template>

    <form @submit="onSubmit">

        <div class="form-floating mb-3">
            <input type="text" class="form-control" name="username" v-bind="usernameAttrs" v-model="username">
            <label for="username">Email o Usuario</label>
            <span style="color:red;">{{ errors.username }}</span>
        </div>

        <div class="form-floating mb-3">
            <input type="password" class="form-control" name="password" v-bind="passwordAttrs" v-model="password">
            <label for="password">Contraseña</label>
            <span style="color:red;">{{ errors.password }}</span>
        </div>
        <button class="ty-btn ty-btn__primary login" :disabled="isSubmitting">{{ isSubmitting ? 'CARGANDO...' : 'INICIAR SESION'}}</button>
    </form>

</template>

<script setup>
import { useForm } from 'vee-validate';
import schemas from "../utils/validations.js";
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { login } from '@/utils/login.js';

const { errors, handleSubmit, isSubmitting, defineField } = useForm({
    validationSchema: schemas.login
});

//eslint-disable-next-line
const [username, usernameAttrs] = defineField('username');
const [password, passwordAttrs] = defineField('password');
//eslint-disable-next-line
const router = useRouter()
const store = useStore();


const onSubmit = handleSubmit(async (values, actions) => login(values, actions, store, router));



</script>