<template>
    <v-dialog v-model="dialogVisible" max-width="500" persistent>

        <template v-slot:default="{ isActive }">
            <v-card title="Advertencia" v-if="isActive">
                <v-card-text>
                    Si se realizaron cambios sin guardar estos van a perderse ¿Desea abandonar la pestaña? </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn text="Cancelar" @click="$emit('closeWarning', false)"></v-btn>
                    <v-btn text="Continuar" @click="$emit('continueNavigation')"></v-btn>
                </v-card-actions>
            </v-card>
        </template>
    </v-dialog>
</template>

<script setup>
import { defineProps, ref, watch, defineEmits } from 'vue'

//eslint-disable-next-line
const emit = defineEmits(['closeWarning']);
const dialogVisible = ref(false)
const props = defineProps({
    activatorProps: {
        type: Boolean,
        required: true,
        default: false
    }
})

watch(() => props.activatorProps, (newValue) => {
    dialogVisible.value = newValue
})


</script>
