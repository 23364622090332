<template>

    <div class="mt-4">

        <v-overlay class="align-center justify-center" v-model="overlay" opacity="0.9">
            <p style="color: #fff;font-size: 1.4rem;width: 20rem;font-weight: bold;text-align: center;">¡Lo siento!
                Actualmente, la sección de administración no está disponible en dispositivos móviles. Sin embargo,
                puedes acceder a ella fácilmente desde tu computadora. ¡Gracias por tu comprensión y paciencia!</p>
        </v-overlay>

        <div class="container" v-if='$store.getters["mobile/isNotMobile"]'>

            <Breadcrumb :items='breadCrumbItems.urls' :names="breadCrumbItems.names"></Breadcrumb>

            <div class="products-container">
                <div class="filter-side" v-if="products.length > 0">
                    <h5 style="font-weight:bold">Productos</h5>
                    <p class="breadcrumb-item"><strong style="color: #000;">{{ totalProducts }}</strong> Resultados</p>

                    <v-skeleton-loader v-if='filteringByCategory && $store.getters["owner/isEdittingPrices"]'
                        type="button" :loading="skeleton" style="width:8rem; background: none;">
                        <div class="input-group mb-3" style="width: 8rem;">

                            <input style="height: unset !important;" type="text" class="form-control"
                                :value="articlesRefs.exposedInputCategory?.surcharge"
                                :placeholder='"Markup de categoria " + selectedCategory'
                                @input="updateInputSurcharge({ RECARGO: $event.target.value, ID: articlesRefs.exposedInputCategory?.id, TIPO: 'CAT' })">
                            <span class="input-group-text">%</span>
                            <span style="width: 100%;color:red;font-size: .7rem;"
                                v-if="articlesRefs.exposedInputCategory?.isError">{{
                                    articlesRefs.exposedInputCategory?.message }}</span>
                        </div>
                    </v-skeleton-loader>

                    <div class="ordering-container">
                        <span> ORDENAR POR </span>
                        <select class="form-select select-ordering" @change="applyFilter">

                            <option v-for="(input, index) in inputs" :key="index" :data-filter="input.filterValue"
                                data-key="order" :selected="input.filterValue === orderPreference ? true : false">
                                {{ input.name }}
                            </option>

                        </select>
                    </div>

                    <v-chip v-for="filter in nonPageFilters" class="mt-1" :key="filter[0]"
                        @click="removeFilter(filter[0])">
                        <strong style="text-transform: capitalize"> {{ normalizeInputsValues(filter) }} &times;</strong>
                    </v-chip>

                    <div class="brands-container" v-if="brands.length > 1">
                        <p class="mt-4" style="margin-bottom:unset !important;"><strong> FILTRAR POR MARCAS </strong>
                        </p>

                        <div class="card card-body" style="width: 50%;padding: unset !important;">
                            <ul class="list-group" :style="brands.length > 8 ? 'height: 20rem;' : ''" v-if="!skeleton">
                                <li v-for="brand in brands" :key="brand.id" data-key="marca" :data-filter="brand.value"
                                    class="list-group-item no-mobile" @click="applyFilter">
                                    {{ brand.marca }}
                                </li>
                            </ul>
                            <ul class="list-group" style="height: 20rem;" v-else>
                                <v-skeleton-loader v-for="brand in brands" :key="brand.id"
                                    type="list-item"></v-skeleton-loader>
                            </ul>
                        </div>
                    </div>
                </div>

                <div class="products-side" style="width: 70%;" v-if="products.length > 0">

                    <div class="text-center" ref="topLevel">
                        <v-pagination next-icon="bi bi-caret-right-fill" prev-icon="bi bi-caret-left-fill" theme="light"
                            :length="productsLen" v-model="page" @update:modelValue="applyFilter"></v-pagination>
                    </div>

                    <div class="products" v-if='$store.getters["owner/isEdittingPrices"]'>
                        <ArticleAdmin ref="articlesRefs" :products="products" :skeleton="skeleton" :filters="filters" @reload-products="fetchArticles()" />
                    </div>
                    <div class="products" v-else>
                        <Article v-for="item in products" :key="item.id" :article="item" :skeleton="skeleton" />
                    </div>

                    <div class="text-center">
                        <v-pagination next-icon="bi bi-caret-right-fill" prev-icon="bi bi-caret-left-fill" theme="light"
                            :length="productsLen" :page="page" @update:modelValue="applyFilter"></v-pagination>
                    </div>
                </div>

                <div style="width: 100%;" v-else>
                    <v-empty-state icon="bi bi-search" style=""
                        text="Intente ajustar sus términos de búsqueda o filtros. Puede contactarnos por cualquiera de nuestros canales para una consulta especifica."
                        title="No pudimos encontrar una coincidencia"></v-empty-state>
                </div>

            </div>

        </div>

        <div v-else>

            <Breadcrumb :items='breadCrumbItems.urls' :names="breadCrumbItems.names" style="margin-left: 1rem">
            </Breadcrumb>

            <h5 style="font-weight:bold; margin-left:1rem !important; text-align:left">Productos</h5>
            <p class="breadcrumb-item" style="margin-left:1rem; text-align:left"><strong style="color: #000;">{{
                totalProducts }}</strong> Resultados</p>

            <div v-if="products.length > 0">

                <div class="filtering-container" ref="topLevel">
                    <v-skeleton-loader type="ossein" :loading="skeleton" height="50px">

                        <button class="button-filters card" type="button" data-bs-toggle="collapse"
                            data-bs-target="#collapse-filter" aria-expanded="false" aria-controls="collapse-filter">
                            <i class="bi bi-sliders"></i>
                            <b>FILTRAR</b>
                        </button>
                        <div class="collapse" id="collapse-filter" style="width: 100%">
                            <div class="card card-body" style="width: 100%;">
                                <ul class="list-group" style="height: 20rem;">
                                    <li v-for="brand in brands" :key="brand.id" data-key="marca"
                                        :data-filter="brand.value" class="list-group-item" @click="applyFilter">
                                        {{ brand.marca }}
                                    </li>

                                </ul>
                            </div>
                        </div>
                    </v-skeleton-loader>

                    <v-skeleton-loader type="ossein" :loading="skeleton" height="50px">
                        <button class="button-filters card" type="button" data-bs-toggle="collapse"
                            data-bs-target="#collapse-ordering" aria-expanded="false" aria-controls="collapse-ordering">
                            <i class="bi bi-arrow-down-up"></i>
                            <b>ORDENAR</b>
                        </button>
                        <div class="collapse" id="collapse-ordering" style="width: 100%">
                            <div class="card card-body" style="width: 100%;">
                                <ul class="list-group">

                                    <li v-for="(input, index) in inputs" :key="index">
                                        <input :data-filter="input.filterValue" data-key="order"
                                            class="form-check-input me-1" type="radio" @click="applyFilter" value=""
                                            :checked="input.filterValue === orderPreference ? true : false">
                                        <label class="form-check-label">{{ input.name }}</label>
                                    </li>

                                </ul>
                            </div>
                        </div>
                    </v-skeleton-loader>


                </div>

                <Article v-for="item in products" :key="item.id" :article="item" renderMobile='true' :skeleton="skeleton" />
            </div>

            <div style="width: 100%;" v-else>
                <v-empty-state icon="bi bi-search" style=""
                    text="Intente ajustar sus términos de búsqueda o filtros. Puede contactarnos por cualquiera de nuestros canales para una consulta especifica."
                    title="No pudimos encontrar una coincidencia"></v-empty-state>
            </div>
            <div class="text-center">
                <v-pagination next-icon="bi bi-caret-right-fill" prev-icon="bi bi-caret-left-fill" v-model="page"
                    theme="light" :length="productsLen" @update:modelValue="applyFilter"
                    :total-visible="totalVisible"></v-pagination>
            </div>

        </div>

    </div>


</template>

<style>
@media (max-width: 1000px) {
    .products-container {
        display: initial;
    }
}

.products>.owl-item {
    margin-bottom: 2.5rem;
}

.list-group-item.no-mobile:hover {
    background-color: #f8f9fa;
    color: #212529;
}

.list-group-item.no-mobile {
    border-right: none !important;
    border-top: none !important;
    border-left: none !important;
    border-radius: unset !important;
    cursor: pointer;
}

.ordering-container>span {
    letter-spacing: 1px;
    font-size: .8rem;
}

.select-ordering {
    width: 13rem !important;
    border: none !important;
    padding: unset !important;
    background-color: unset !important;
    font-size: .85rem !important;
    font-weight: bold !important;
}

.filter-side {
    width: 30%;
    text-align: left
}

.list-group-item {
    text-align: left;
}

.products-container {
    display: flex;
    justify-content: flex-start;
    align-items: baseline;
}

.products-side {
    width: 70%;
}

.list-group {
    overflow-y: scroll;
}


.button-filters {
    width: 100%;
    background: #fff;
    padding: 1rem;
    display: flex !important;
    justify-content: center;
    flex-direction: row !important;
    gap: .4rem;
}

.card-text,
.card-title {
    margin: unset !important;
}

.make-flexible {
    display: flex;
    gap: 1rem;
    justify-content: space-around;
}

.carousel-control-prev.products,
.carousel-control-next.products {
    position: unset !important;
    width: unset !important;
}

.carousel-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}


.products {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-evenly;
}
</style>

<script setup>
// @ is an alias to /src

import { onMounted, ref, watch, computed, defineProps, getCurrentInstance, onBeforeMount } from 'vue';
import { useStore } from 'vuex';
import { useRouter, useRoute } from 'vue-router';
import { dummyData } from '@/utils/utils.js';

import Article from "@/components/ArticleComponent.vue";
import Breadcrumb from "@/components/BreadcrumbComponent.vue";
import ArticleAdmin from "@/components/ArticleAdminComponent.vue";
import axios from "../services/axios.js";

async function fetchArticles() {
    try {
        const url = new URL(window.location.href)
        const userMoneyPreference = store.getters["currency/currency"]
        let index = 0;
        imagesLoaded = 0

        skeleton.value = true;
        products.value = dummyData("products", 30) //Force products reload
        
        if (url.searchParams.get("order") === null && localStorage.getItem("orderPreference") !== null) {
            url.searchParams.set("order", localStorage.getItem("orderPreference"))
        }

        url.searchParams.set("user_money_preference", userMoneyPreference)

        data = await axios.fetchData(url.toString().replace(window.origin, "")); //Remove hostname
        manageBreadcrumbNavigation()

        data.products.forEach((element) => {
            
            element.inputSurcharge = element.surchargeApplied.type === "ART" ? element.surchargeApplied.value : 0
            element.isTheLast = index === data.products.length - 1 ? true : false
            index++
        });

        
        products.value = data.products;
        brands.value = data.brands;
        productsLen = data.amountOfPages;
        totalProducts.value = data.totalProducts;
        orderPreference.value = localStorage.getItem("orderPreference") ? localStorage.getItem("orderPreference") : "recent-updates"


    } catch (error) {
        console.error("Error al obtener los productos:", error);
    }
};
function manageBreadcrumbNavigation() {

    if (router.currentRoute.value.query.categoria !== undefined) {
        const param = router.currentRoute.value.query.categoria
        const parent = param.includes("--") ? decodeURIComponent(param.split("--")[0]) : decodeURIComponent(param)
        const children = param.includes("--") ? decodeURIComponent(param.split("--")[1]) : ""

        breadCrumbItems.value.urls[2] = { path: `/productos`, query: { categoria: parent } }
        breadCrumbItems.value.names[2] = parent

        if (children !== "") {
            breadCrumbItems.value.urls[3] = { path: `/productos`, query: { categoria: children } }
            breadCrumbItems.value.names[3] = children
        } else if (breadCrumbItems.value.urls.length === 4) {
            breadCrumbItems.value.urls.pop()
            breadCrumbItems.value.names.pop()
        }

    } else {

        breadCrumbItems.value = {
            urls: [{ path: "/" }, { path: "/productos" }],
            names: ["Home", "Productos"]
        }

    }

}
function normalizeInputsValues(filterArray) {

    let key = filterArray[0].toLowerCase()
    let value = filterArray[1].toLowerCase()

    value = value.includes("--") ? value.split("--")[1] : value

    let text = `${key}: ${decodeURIComponent(value)}`

    if (key === "order") {

        text = "Orden: "
        if (value === "asc-prices") {
            text += "Precios mayor a menor"
        } else if (value === "asc-names") {
            text += "Nombres: A - Z"

        } else if (value === "desc-prices") {
            text += "Precios menor a mayor"

        } else if (value === "desc-names") {
            text += "Nombres: Z - A"

        } else if (value === "recent-updates") {
            text += "Actualizados recientemente"

        }
    }


    return text
}
function applyFilter(event) {

    let target, key, value;

    if (event.target !== undefined) {
        target = event.type === "change" ? event.target.selectedOptions[0] : event.target
    }

    key = target === undefined ? "page" : target.getAttribute("data-key")
    value = target === undefined ? event : target.getAttribute("data-filter")

    filters.value[key] = value

    if (key === "order") {
        localStorage.setItem("orderPreference", value)
    }

    if (key !== "page") {
        page.value === 1
        filters.value["page"] = 1
    } else {
        topLevel.value.scrollIntoView({ block: 'center', behavior: 'smooth' });
    }

    router.push({ path: "/productos", query: { ...filters.value } });

}
function removeFilter(filterKey) {

    delete filters.value[filterKey]

    if (filters.value["page"]) {
        filters.value["page"] = 1
        page.value = 1
    }

    router.push({ path: "/productos", query: { ...filters.value } });


}

const router = useRouter()
const route = useRoute()
const store = useStore()
const page = ref(1)
const overlay = computed(() => store.getters["mobile/mobile"] && store.getters["owner/isEdittingPrices"])
const emitter = getCurrentInstance()?.appContext.app.config.globalProperties.emitter;
const successfullyCharged = () => {
    if (imagesLoaded === (products.value.length - 1) || imagesLoaded === products.value.length) {
        skeleton.value = false
    }

    imagesLoaded++
}

let data, configuration;
let imagesLoaded = 0
let articlesRefs = ref([])
let topLevel = ref(null)
let skeleton = ref(true)
let products = ref([]);
let brands = ref(dummyData("brands", 15));
let totalProducts = ref(0)
let filters = ref({})
let productsLen = ref(0)
let breadCrumbItems = ref([])
let filteringByCategory = ref(false)
let inputs = [
    {
        name: "Actualizados recientemente",
        filterValue: "recent-updates",
    },
    {
        name: "Precios: mayor a menor",
        filterValue: "asc-prices",
    },
    {
        name: "Precios: menor a mayor",
        filterValue: "desc-prices",
    },
    {
        name: "Nombres: A - Z",
        filterValue: "asc-names",
    },
    {
        name: "Nombres: Z - A",
        filterValue: "desc-names",
    }
]

let nonPageFilters = computed(() => Object.entries(filters.value).filter(([key]) => key !== 'page' && key !== "categoria" && key !== "order"));
let totalVisible = computed(() => store.getters["mobile/mobile"] ? 4 : 11)
let orderPreference = ref("")
let selectedCategory = computed(() => {
    let lastIndex = breadCrumbItems.value.names.length
    return breadCrumbItems.value.names[lastIndex - 1] !== undefined ? breadCrumbItems.value.names[lastIndex - 1] : ""
})

defineProps({
    category: String,
    brand: String,
    order: String,
})

onBeforeMount(() => filters.value = {})
onMounted(async () => {
    //eslint-disable-next-line
    let url = new URL(window.location.href)
    let query = router.currentRoute.value.query
    let generalMarkup;

    breadCrumbItems.value = {
        urls: [{ path: "/" }, { path: "/productos" }],
        names: ["Home", "Productos"]
    }

    if (Object.entries(query).length > 0) {
        Object.assign(filters.value, query)
        page.value = "page" in query ? query.page : 1
    }

    orderPreference.value = localStorage.getItem("orderPreference") ? localStorage.getItem("orderPreference") : "recent-updates"
    await fetchArticles()

    if (store.getters["owner/isEdittingPrices"]) {
        configuration = store.getters["website/siteConfiguration"].surcharges
        generalMarkup = configuration.find(element => element.TIPO === "TODOS")?.RECARGO
        emitter.emit("handleTotalSurcharge", {
            ID: "-1",
            RECARGO: generalMarkup === undefined ? 0 : generalMarkup,
            validations: {
                isError: false,
                message: "",
            }
        })
    }

    emitter.on('category', async (e) => applyFilter(e));
    emitter.on('imageLoaded', async (e) => successfullyCharged(e))


});

watch(() => route.query, async () => {

    filteringByCategory.value = "categoria" in route.query ? true : false

    if (filteringByCategory.value) {
        //filters.value = Object.entries(route.query).length > 1 ? {...filters.value,...router.currentRoute.value.query} : route.query 
        let aux = Object.entries(route.query).length > 1 ? filters.value : {}
        filters.value = { ...aux, ...route.query }

    } else if (Object.entries(router.currentRoute.value.query).length === 0) {
        filters.value = {}
    }

    if (!("page" in route.query)) page.value = 1


    await fetchArticles()

})
store.subscribe(async (mutation) => {

    let generalMarkup;

    if (mutation.type === "currency/setCurrency") {
        await fetchArticles()
        skeleton.value = false
    }

    if (mutation.type === "website/setConfiguration" && store.getters["owner/isEdittingPrices"]) {
        configuration = store.getters["website/siteConfiguration"].surcharges
        generalMarkup = configuration.find(element => element.TIPO === "TODOS")?.RECARGO

        emitter.emit("handleTotalSurcharge", {
            ID: "-1",
            RECARGO: generalMarkup === undefined ? 0 : generalMarkup,
            validations: {
                isError: false,
                message: "",
            }
        })
    }

})

function updateInputSurcharge(data) {
    //collectMarkups({ RECARGO: $event.target.value, ID: articlesRefs.inputCategory?.id, TIPO: 'CAT' }, articlesRefs.inputCategory?.id)

    let timeout;
    clearTimeout(timeout)
    timeout = setTimeout(() => {
        articlesRefs.value.collectMarkups(data, articlesRefs.value.exposedInputCategory.id)
    }, 400)
}

</script>