<!-- Footer.vue -->
<template>
  <footer>

    <v-footer color="#26323e" elevation="24" ref="test">
      <div style="display: flex;gap: 3rem;justify-content: center; width: 100%;">
        <p v-if='!$store.getters["owner/isEdittingInfo"]' class="footer-info">{{ address }}</p>
        <div v-else class="input-group" style="width: 20rem;">

          <input style="height:unset !important;caret-color: #000;" @input="processInput({DIRECCION: $event.target.value, friendlyName: 'Direccion'}, 'addressChange')" :value = "inputAddress" type="text"
            class="form-control" placeholder="Direccion">
          <span class="input-group-text"><i class="bi bi-pencil-fill"></i></span>
        </div>

        <p v-if='!$store.getters["owner/isEdittingInfo"]' class="footer-info">{{ mail }}</p>
        <div v-else class="input-group" style="width: unset;">
          <input style="height:unset !important;caret-color: #000;width: 30rem;" @input="processInput({MAIL: $event.target.value, friendlyName: 'Email de contacto'}, 'mailChange')" :value = "inputMail" type="text"
            class="form-control" placeholder="Mail">
          <span class="input-group-text"><i class="bi bi-pencil-fill"></i></span>
        </div>

        <p v-if='!$store.getters["owner/isEdittingInfo"]' class="footer-info">{{ phones }}</p>
        <div v-else class="input-group" style="width: unset;">
          <input style="height:unset !important;caret-color: #000;width: 30rem;" @input="processInput({TELEFONOS: $event.target.value, friendlyName: 'Telefonos'}, 'phoneChange')" :value = "inputPhone" type="text"
            class="form-control" placeholder="Telefonos">
          <span class="input-group-text"><i class="bi bi-pencil-fill"></i></span>
        </div>
      </div>

    </v-footer>

    <v-footer color="#f5f5f5">
      <p class="footer-info"
        style="margin: 0px auto !important; color: #9e9e9e !important;width: 100%;font-size: .7rem;">® MiHard no vende
        estos artículos y no participa en ninguna negociación, venta o perfeccionamiento de operaciones. Solo se limita
        a la publicación de anuncios de sus usuarios. </p>
    </v-footer>



  </footer>
</template>

<script setup>
import { defineModel, ref, getCurrentInstance, onMounted } from 'vue'
import { useStore } from 'vuex'

const processInput = (data, eventName) => emitter.emit(eventName, data)
const store = useStore()
const inputPhone = defineModel("inputPhone")
const inputAddress = defineModel("inputAddress")
const inputMail = defineModel("inputMail")
const phones = ref("")
const address = ref("")
const mail = ref("")
const emitter = getCurrentInstance()?.appContext.app.config.globalProperties.emitter;

store.subscribe((mutation) => {

  if (mutation.type === "website/setConfiguration") {
    phones.value = store.getters["website/siteConfiguration"].TELEFONOS
    address.value = store.getters["website/siteConfiguration"].DIRECCION
    mail.value = store.getters["website/siteConfiguration"].MAIL

    inputPhone.value = phones.value
    inputAddress.value = address.value
    inputMail.value = mail.value
  }
})

onMounted(() => {
  inputPhone.value = store.getters["website/siteConfiguration"]?.TELEFONOS
  inputAddress.value = store.getters["website/siteConfiguration"]?.DIRECCION
  inputMail.value = store.getters["website/siteConfiguration"]?.MAIL
})

</script>

<style scoped>
/* Estilos del footer */

.span4 {
  text-align: left !important;
}

.footer-link {
  margin: unset !important;
}
</style>