
// initial state
// shape: [{ id, quantity }]
const state = {
    categories: []
}

// getters
const getters = {
    categories: (state) => {
        return state.categories;
    },
}

// actions
const actions = {
    setCategories({ commit }, categories) {
        commit("setCategories", { categories })
    },
}

const mutations = {
    setCategories(state, { categories }) {
        state.categories = categories
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
