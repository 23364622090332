import * as yup from 'yup';

let schemas = {
    email: yup.object({
        email: yup.string().email("Email invalido").required("Este campo no puede estar vacio")
    }),
    login: yup.object({
        username: yup.string().required("Este campo no puede estar vacio"),
        password: yup.string().required("Este campo no puede estar vacio")
        //.matches(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{6,}$/, "La CLAVE debe tener al menos una letra mayúscula, una letra minúscula, un dígito y un mínimo de 6 caracteres")
    }),
}

export default schemas