<template>
    <div v-if=!renderMobile class="owl-item">
        <div class="bbb_viewed_item">
            <div class="content">
                <div class="bbb_viewed_image">
                    <v-skeleton-loader v-if="skeleton === true" type="image" :loading="skeleton"
                        width="256px"></v-skeleton-loader>
                    <img class="images_prd" :class="{ 'visually-hidden': skeleton }" width="210px" :src="articleImage"
                        :alt="'Imagen de' + article.denominacion" @load="checkIfIsTheLast">
                </div>

                <div class="bbb_viewed_content">
                    <div class="bbb_viewed_price">
                        <v-skeleton-loader type="button" :loading="skeleton" width="150px">

                            <div v-if='$store.getters["owner/isEdittingPrices"]' class="input-group"
                                :class="{ isInvalid: article.isError, 'mb-3': !$store.getters['owner/isEdittingPrices'] }"
                                style="width: 7rem;margin: 0 auto;">

                                <input style="height: unset !important;" :class="{ 'is-invalid': article.isError }"
                                    type="text" class="form-control" placeholder="Markup" :value="surcharge"
                                    @input="$emit('handleInput', { RECARGO: $event.target.value, ID: article.id, TIPO: 'ART' })">
                                <span class="input-group-text">%</span>

                            </div>
                        </v-skeleton-loader>

                        <span class="error-message" v-if="article.isError">{{ article.message }}</span>

                        <v-skeleton-loader v-else type="text" :loading="skeleton" width="150px">
                            <div v-if="$store.getters['owner/isEdittingPrices']">
                                <span class="text-surcharge-applied">Aplicando %{{ article.surchargeApplied.value }} de
                                    <span style="text-decoration: underline;cursor:pointer;">
                                        recargo<i class="bi bi-exclamation-circle-fill"></i>
                                        <v-tooltip activator="parent" location="end" width="190px">{{ surchargeExplain
                                            }}</v-tooltip>
                                    </span>
                                </span>
                                <div style="display: flex;justify-content: space-around;">
                                    <span>{{ article.originalPrice }}</span>
                                    <span>&rarr;</span>
                                    <span>{{ article.price }}</span>
                                </div>
                            </div>
                            <b v-else> {{ article.price }}</b>
                        </v-skeleton-loader>

                        <div class="bbb_viewed_stock">
                            <v-skeleton-loader type="text" :loading="skeleton" width="130px">
                                <b :class="[stockClass()]" class="alerta">
                                    {{ stock() }}
                                    <i class="bi bi-box-seam-fill stockIcon" :class="[stockClass()]"
                                        aria-hidden="true"></i>
                                </b>
                            </v-skeleton-loader>
                        </div>

                    </div>
                    <div class="bbb_viewed_name">
                        <v-skeleton-loader type="sentences" :loading="skeleton" width="200px">
                            <span class="vista_rapida_p"> {{ article.denominacion }}</span>
                        </v-skeleton-loader>
                    </div>
                </div>
            </div>

        </div>
    </div>
    <div v-else class="bbb_viewed_item_mobile row g-0">
        <div class="col-md-4" style="width: unset;">
            <v-skeleton-loader v-if="skeleton === true" type="image" :loading="skeleton"
                width="256px"></v-skeleton-loader>
            <img class="images_prd" :class="{ 'visually-hidden': skeleton }" width="100px" :src="articleImage"
                :alt="'Imagen de' + article.denominacion" @load="checkIfIsTheLast">
        </div>
        <div class="col-md-8" style="width: 70%;">
            <div class="card-body" style="text-align: left;">
                <v-skeleton-loader type="sentences" :loading="skeleton" width="300px">
                    <span class="card-title"> <strong> {{ article.denominacion }}</strong></span>
                </v-skeleton-loader>
                <div>
                    <v-skeleton-loader type="text" :loading="skeleton" width="150px">
                        <p class="card-text">{{ article.price }}</p>
                    </v-skeleton-loader>

                    <v-skeleton-loader type="text" :loading="skeleton" width="150px">
                        <b class="card-text stock-text" :class="[stockClass(article.stock)]">
                            {{ stock(article.stock) }}
                            <i class="bi bi-box-seam-fill stockIcon" :class="[stockClass(article.stock)]"
                                aria-hidden="true"></i>
                        </b>
                    </v-skeleton-loader>
                </div>
            </div>
        </div>
    </div>
</template>

<style>
@media (max-width: 1000px) {
    .card-text.stock-text {
        font-size: .6rem;
    }
}

.error-message {
    width: 100%;
    color: red;
    font-size: .7rem;
    text-align: center !important;
    padding: 0 1rem;
}

.text-surcharge-applied {
    text-transform: uppercase;
    font-size: .6rem;
    letter-spacing: 1px;
}

.bbb_viewed_item_mobile {
    flex-wrap: nowrap;
    align-items: center;
    background: #fff;
    border-bottom: 1px solid #f4dfdf;
}

.v-skeleton-loader__button {
    max-width: unset !important;
}

.bbb_viewed_price {
    display: flex;
    flex-direction: column;
    align-items: center;
}


.vista_rapida_p {
    text-decoration: none !important;
    color: #465f73 !important;
    padding: .5rem
}

.isInvalid.input-group {
    margin-bottom: unset !important;
}

.content {
    height: 80%;
    display: inline-flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    gap: 3.5rem;
}
</style>

<script setup>
import { defineProps, computed, ref, getCurrentInstance } from 'vue';

const props = defineProps({
    article: {
        type: Object,
    },
    renderMobile: {
        type: Boolean,
    },
    isError: {
        type: Boolean,
        default: false
    },
    message: {
        type: String,
        default: ""
    },
    surcharge: {
        type: String,
        default: "0"
    },
    skeleton: {
        type: Boolean,
        default: false,
        required: true
    }
});

const stockClass = () => {
    let stockValue = parseInt(props.article.stock);
    if (stockValue === 15) return 'stockDisponible';
    else if (stockValue === 10) return 'stockMedio';
    else return 'stockBajo';
};

const stock = () => {
    let stockValue = parseInt(props.article.stock);
    if (stockValue === 15) return 'STOCK ALTO';
    else if (stockValue === 10) return 'STOCK MEDIO';
    else return 'STOCK BAJO';
}

const surchargeExplain = computed(() => {

    let text = ''

    if (props.article.surchargeApplied.type === "ART") {
        text = 'Aplicado por recargo de producto'
    }

    if (props.article.surchargeApplied.type === "CAT") {
        text = 'Aplicado por categoria del producto'
    }

    if (props.article.surchargeApplied.type === "TODOS") {
        text = 'Aplicado por recargo general'
    }

    return text;

})

const emitter = getCurrentInstance()?.appContext.app.config.globalProperties.emitter;
const checkIfIsTheLast = () => {

    if (props.article.image !== "0.jpg") {
        emitter.emit('imageLoaded', props.article.id)
    }
}
let articleImage = ref("")

if (props.article.image !== "0.jpg") {
    fetch(`/portal.php?PATH=${props.article.image}`)
        .then((response) => {

            if (!response.ok) {
                throw new Error("Error al cargar la imagen")
            }

            return response.blob()
        })
        .then((blob) => {
            articleImage.value = URL.createObjectURL(blob)

        })
        .catch((error) => {
            console.log("🚀 ~ onMounted ~ error:", error)
        })

}


</script>
