import axios from "../services/axios.js";

async function login(values, actions, store, router) {

    try {

        const http = await axios.postData(`/login`, values)
        const clientData = http.data.clientData
        const token = http.data.token
        const isOwner = http.data.isOwner
        console.log("🚀 ~ login ~ isOwner:", isOwner)

        store.dispatch("login/login", { login: true, token })
        localStorage.setItem("username", clientData);
        localStorage.setItem("token", token);

        if (isOwner) {
            store.dispatch("owner/setOwner", { isOwner: true })
        } else {
            router.go()
        }


    } catch (error) {
        console.log("🚀 ~ onSubmit ~ error:", error)

        if ("response" in error) {

            if (error.response.status === 400) {
                actions.setFieldError("password", "Credenciales Invalidas")
                return;
            }

            if (error.response.status === 403) {
                console.log("Verificar usuario")
                return
            }

            actions.setFieldError("password", "Ha ocurrido un error inesperado")
        }

    }

}

async function logout(store, router) {
    try {

        await axios.fetchData(`/logout`)

        store.dispatch("login/login", { login: false, token: null })
        localStorage.removeItem("username");
        localStorage.removeItem("token");

        router.go()

    } catch (error) {
        console.log("🚀 ~ onSubmit ~ error:", error)

    }
}

export { login, logout }
