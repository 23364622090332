<template>


  <v-dialog v-model="dialog" width="auto">

    <v-sheet :min-height="300" :max-height="500" :width="600">

      <SuccessAlert @close-modal="() => dialog = false" :hgt="sucessfullyHeight"
        message="Los cambios ya se pueden ver reflejados en la web" buttonTextGo="Seguir editando"
        buttonTextClose="Cerrar" v-if="sucessfully" />

      <div ref="modal" class="modal-body" v-else>
        <div class="modal-header">
          <p style="font-size: 1.4rem;font-weight: 500;margin:0 2rem;color:#fff">¡Ultimos detalles! Confirme los datos
          </p>
          <v-btn elevation="0" width="30px" height="30px" base-color="#ff5319" rounded=false class="ms-auto"
            icon="bi bi-x" @click="() => dialog = false"></v-btn>
        </div>

        <div v-if="$store.getters['owner/isEdittingPrices']" class="form-text" id="basic-addon4"
          style="text-align: center;font-size: .65rem !important;">
          *Al eliminar un cambio, el valor se restaurará al valor original que se cargó en un principio.
          <br>
          Esto significa que cualquier ajuste que hayas realizado será revertido al valor inicial.
        </div>

        <div v-if="!noChanges">
          <div class="section" :class="{ scroll: changes[tag].length > 6 }" v-for="(tag, index) in tags" :key="index">

            <p class="section-title">{{ tag }}</p>

            <div class="input-group mb-3" v-for="(item, itemIndex) in changes[tag]" :key="item.id"
              :class="{ 'hide-absolute': changes[tag][itemIndex].hide }" style="width: 15.4rem;">
              <label class="form-label" style="width: 100%;">{{ item.name }}</label>

              <span v-if="item.previousValue && $store.getters['owner/isEdittingPrices']" class="input-group-text"> {{
                item.previousValue }}% &rarr;</span>
              <input style="height: unset !important;" @input="handleInputs($event.target.value, tag, itemIndex)"
                ref="inputsRefs" :id="tag + item.id" type="text" class="form-control"
                :class="{ 'is-invalid': item.isError }" :value="item.value">
              <span v-if="$store.getters['owner/isEdittingPrices']" class="input-group-text">%</span>

              <button class="btn btn-danger input-group-text" @click="discardChange(index, itemIndex, tag)">
                <i class="bi bi-trash3-fill"></i>
              </button>

              <div v-if="item.isError" class="invalid-feedback">
                {{ item.message }}
              </div>
            </div>

            <v-divider :thickness="2"></v-divider>
          </div>
        </div>
        <p v-else style="text-align: center;margin-top: auto !important;" class="section-title">Has borrado todos los
          datos de contacto, si asi lo desea, <br> puede presionar el boton de guardar.</p>


        <div class="modal-footer">
          <v-btn height="40px" base-color="#4FBE31" rounded="0" @click="save()" class="ms-auto save-modal-admin"
            :loading="loading" text="Guardar" append-icon="bi bi-caret-right-fill"></v-btn>
        </div>

      </div>
    </v-sheet>


  </v-dialog>

  <v-skeleton-loader type="avatar" :loading="skeleton"
    style="position:fixed; right:0; background: none !important; bottom:144px; margin: 12px; width:80px"
    v-if='$store.getters["owner/isEdittingInfo"] || $store.getters["owner/isEdittingPrices"]'>
    <v-fab @click="exit" class="me-4 exit-admin-mode" location="top right" fixed prepend-icon="bi bi-box-arrow-left"
      rounded="circle" width=60 height=60 color="#dc3545"></v-fab>
  </v-skeleton-loader>

  <v-skeleton-loader type="avatar" :loading="skeleton"
    style="position:fixed; right:0; background: none !important; bottom:72px; margin: 12px; width:80px"
    v-if='$store.getters["owner/isEdittingInfo"] || $store.getters["owner/isEdittingPrices"]'>
    <v-fab @click="recopileData" class="me-4 save-button-home" location="bottom end" fixed :prepend-icon="icon"
      rounded="circle" width=60 height=60 color="#4fbe31"></v-fab>
  </v-skeleton-loader>


</template>

<style>
.modal-body {
  min-height: 300px;
  display: flex;
  flex-direction: column;
}

.exit-admin-mode {
  top: unset !important;
}

.section.scroll {
  height: 25rem;
  overflow-y: scroll;
  overflow-x: hidden;
  padding-left: .1rem;
}

.hide-absolute {
  display: none !important;
}

.section {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin: 0 3rem;
  gap: .5rem;
  align-items: flex-start;
}

.section-title {
  width: 100%;
  margin: unset !important;
  font-weight: 500;
  font-size: 1rem
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ff5319;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: sticky;
  top: 0;
  z-index: 6;
}

.modal-footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: auto
}

.modal-header>button {
  margin-right: 1rem;
}

.save-modal-admin {
  margin: 0 1rem 1rem 1rem !important;
}
</style>

<script setup>

import { ref, defineProps, defineEmits } from 'vue'
import SuccessAlert from '@/components/SuccessfullyAlert.vue'
//eslint-disable-next-line
import axios from "../services/axios.js";
import { useStore } from 'vuex';

const emit = defineEmits(['removeSurchargeChange', 'reloadProducts']);
const dialog = ref(false)
const loading = ref(false)
const icon = ref("bi bi-floppy-fill")
const store = useStore()
const props = defineProps({
  webConfiguration: Object,
  skeleton: Boolean
})

let noChanges = ref(false)
let changes = ref({})
let tags = ref([])
let inputsRefs = ref([])
let sucessfully = ref(false)
let sucessfullyHeight = ref(115)
let modal = ref(null)
let config;

const discardChange = (indexTag, indexItem, tag) => {
  console.log("🚀 ~ discardChange ~ changes.value[tag][indexItem]:", changes.value[tag][indexItem])

  let data = { type: changes.value[tag][indexItem].type, id: changes.value[tag][indexItem].id }
  changes.value[tag].splice(indexItem, 1)
  config.splice(indexItem, 1)

  if (changes.value[tag].length === 0) {
    delete changes.value[tag]
    tags.value.splice(indexTag, 1)
  }

  if (Object.entries(changes.value).length === 0) {
    dialog.value = false
  }


  emit('removeSurchargeChange', data)

}

function exit() {
  store.dispatch(`owner/setPricesMode`, false)
  store.dispatch(`owner/setInfoMode`, false)

  window.location.reload()

}

function recopileData() {

  let webConfiguration = props.webConfiguration
  //let count = 0

  if (!Array.isArray(webConfiguration)) {

    if (Object.entries(webConfiguration).length > 0) {
      if (Object.values(webConfiguration).find((element) => typeof element === "object") !== undefined) {
        webConfiguration = Object.values(webConfiguration).map((element) => Object.values(element)).flat()
      }

    } else {
      return [];
    }

  } else if (webConfiguration.length <= 0) {
    return [];
  }

  changes.value = {}
  tags.value = []

  for (const element of webConfiguration) {
    console.log("🚀 ~ recopileData ~ element:", element)
    let item = {}

    if (tags.value.find((tag) => tag === element.metadata.section) === undefined) {
      changes.value[element.metadata.section] = []
      tags.value.push(element.metadata.section)
    }

    item.previousValue = element.metadata.previousValue
    item.name = element.metadata.name
    item.value = element.metadata.input
    item.isError = (element.validations?.isError) ?? false
    item.message = (element.validations?.message) ?? ""
    item.hide = false
    item.id = element.ID
    item.type = element.TIPO === undefined && element.metadata.section === "Otros" ? Object.keys(element)[2] : element.TIPO
    changes.value[element.metadata.section].push(item)
    //if(item.value === "" && element.metadata.section === "Otros") count++

  }
  
  //noChanges.value = count === 3 ? true : false
  sucessfully.value = false
  dialog.value = true
  config = webConfiguration

}

function handleInputs(value, tag, index) {

  console.log("🚀 ~ handleInputs ~ changes.value[tag][index]:", changes.value[tag][index])

  if (changes.value[tag][index].type === "SLOGAN") {

    if (value.length > 70) {
      changes.value[tag][index].isError = true
      changes.value[tag][index].message = "Cant. maxima de caracteres: 70"
    } else {
      changes.value[tag][index].isError = false
      changes.value[tag][index].message = ""
    }

    changes.value[tag][index].value = value
  } else if (tag !== "Otros") {
    const regex = /^\d*\.?\d*$/
    if (value === "") {
      changes.value[tag][index].value = 0
    } else {
      changes.value[tag][index].isError = !regex.test(value) ? true : false
      changes.value[tag][index].message = !regex.test(value) ? "Solo numeros positivos; Para numeros con coma usar un punto (.) y NO una coma (,)" : ""
      changes.value[tag][index].value = value
    }
  }
}

async function save() {

  loading.value = true

  let data = [];
  let itemWithError;

  for (const [key, value] of Object.entries(changes.value)) {
    itemWithError = value.find((element) => element.isError)

    if (itemWithError !== undefined) {
      inputsRefs.value.find((element) => element.id === `${key}${itemWithError.id}`).scrollIntoView({ block: 'center', behavior: 'smooth' });
      loading.value = false
      return;
    }

  }

  if (itemWithError === undefined) {

    for (const tag of tags.value) {

      const elements = config.filter((element) => element.metadata.section === tag)

      for (let index = 0; index < changes.value[tag].length; index++) {
        let row = elements[index].metadata.row
        elements[index][row] = changes.value[tag][index].value
      }


      elements.forEach(element => {
        let aux = Object.assign({}, element)
        delete aux?.metadata
        delete aux?.validations
        data.push(aux)
      })
    }


    try {
      await axios.postData("/admin/cambiar-configuracion", { webConfiguration: data })

      emit('reloadProducts')

      setTimeout(() => {
        sucessfullyHeight.value = modal.value.offsetHeight
        sucessfully.value = true
        loading.value = false

      }, 1300)

    } catch (e) {
      loading.value = false
      console.log("🚀 ~ save ~ e:", e)
    }
  }
}

</script>