
// initial state
// shape: [{ id, quantity }]
const state = {
    owner: JSON.parse(localStorage.getItem("owner")) || false,
    editPrices: false, 
    editInfo: false 
}

// getters
const getters = {
    isOwner: (state) => {
        return state.owner;
    },
    isEdittingPrices: (state) => {
        return state.editPrices;
    },
    isEdittingInfo: (state) => {
        return state.editInfo;
    }
}

// actions
const actions = {
    setOwner({ commit }, isOwner) {
        commit('owner/setOwner', { isOwner }, { root: true })
        localStorage.setItem("owner", JSON.stringify({ owner: isOwner }))
    },
    setPricesMode({ commit }, editPrices) {
        commit('owner/setPricesMode', { editPrices }, { root: true })
    },
    setInfoMode({ commit }, editInfo) {
        commit('owner/setInfoMode', { editInfo }, { root: true })
    }
}

// mutations
const mutations = {

    setOwner(state, { isOwner }) {
        state.owner = isOwner
    },
    setPricesMode(state, { editPrices }) {
        state.editPrices = editPrices
    },
    setInfoMode(state, { editInfo }) {
        state.editInfo = editInfo
    }

}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
