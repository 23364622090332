import axios from "axios";
import store from "@/store/index.js";
import router from "@/router/index.js";

//import { synchronizeConfig } from '../utils/utils.js'

axios.interceptors.request.use(
  function (config) {
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${localStorage.getItem("token")}`;
    axios.defaults.headers.common[
      "x-client-host"
    ] = `${window.location.hostname}`;
    return config;
  },
  function (error) {
    console.log("🚀 ~ error:", error);
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data

    if (response.headers["content-type"] && !response.headers["content-type"].includes("image")) {
      const actualConfig = store.getters["website/siteConfiguration"];
      const customResponse = "configuration" in response.data ? response.data.info : response;
      const areArraysEqual = (arr1, arr2) => {
        if (arr1.length !== arr2.length) return false;

        const sortedArr1 = arr1.slice().sort();
        const sortedArr2 = arr2.slice().sort();

        return JSON.stringify(sortedArr1) === JSON.stringify(sortedArr2);
      };

      let config, areEquals;

      if ("configuration" in response.data) {
        config = response.data.configuration;

        if (Object.entries(actualConfig).length === 0) {
          store.dispatch("website/setConfiguration", config);
        } else {
          //eslint-disable-next-line
          for (let [key, value] of Object.entries(actualConfig)) {
            if (key === "surcharges") {
              if ("surcharges" in config) {
                areEquals = areArraysEqual(
                  actualConfig[key],
                  config.surcharges
                );

                if (!areEquals) {
                  store.dispatch("website/setConfiguration", config);
                  break;
                }
              } else {
                store.dispatch("website/setConfiguration", config);
                break;
              }
            } else if (actualConfig[key] !== config[key]) {
              store.dispatch("website/setConfiguration", config);
              break;
            }
          }
        }
      }

      if ("catalogue" in response.data) {
        store.dispatch("categories/setCategories", response.data.catalogue);
      }

      return customResponse;
    } else {
      return response;
    }
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error

    if (error.response?.data === "TokenExpiredError") {
      store.dispatch("login/login", { login: false, token: false });
      localStorage.removeItem("username");
      localStorage.removeItem("token");
      localStorage.removeItem("logged");
      router.push("/admin");
    }

    return Promise.reject(error);
  }
);

axios.defaults.baseURL = "https://api.mihard.com/api"

export default {
  async fetchData(url) {
    return await axios.get(url, {});
  },
  async getData(url) {
    return await axios.get(url);
  },
  postData(url, data) {
    return axios.post(url, {
      data,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
};
